import React from 'react';

const ValidationText = (props: {
  validationState: boolean | undefined;
  text: string;
  successColor?: string;
  invalidColor?: string;
}) => {
  if (props.validationState === undefined) {
    return <small>{props.text}</small>;
  }
  return (
    <small
      style={
        props.validationState === true
          ? { color: props.successColor || 'green', fontWeight: 'bold' }
          : { color: props.invalidColor || '' }
      }
    >
      {props.text}
    </small>
  );
};

export default ValidationText;
