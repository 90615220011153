var isExisty = function isExisty(value: number | string) {
  return value !== null && value !== undefined;
};

var _isEmpty = function _isEmpty(value: number | string) {
  return value === '' || !isExisty(value);
};

///Validation Rules
var validations = {
  matchRegexp: function matchRegexp(value: string, regexp: RegExp) {
    return _isEmpty(value) || regexp.test(value);
  },

  // eslint-disable-next-line
  isEmail: function isEmail(value: string) {
    return validations.matchRegexp(
      value,
      /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i
    );
  },

  NotEmpty: function isEmpty(value: number | string) {
    return !_isEmpty(value);
  },

  IsEmpty: function isEmpty(value: number | string) {
    return _isEmpty(value);
  },
  isNumber: function isNumber(value: any) {
    // return validations.matchRegexp(value, /^-?[0-9]\d*(\d+)?$/i);
    return validations.matchRegexp(
      value,
      /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/gm
    );
  },

  // isFloat: function isFloat(value:any) {
  //     return validations.matchRegexp(value, /^(?:-?[1-9]\d*|-?0)?(?:\.\d+)?$/i);
  // },
  hasNumber: function hasNumber(value: string) {
    return /\d/.test(value);
  },
  // isPositive: function isPositive(value:any) {
  //     if (isExisty(value)) {
  //         return (validations.isNumber(value) || validations.isFloat(value)) && value >= 0;
  //     }
  //     return true;
  // },

  // maxNumber: function maxNumber(value:number, max:number) {
  //     return _isEmpty(value) || value <= max;
  // },

  // minNumber: function minNumber(value:number, min:number) {
  //     return _isEmpty(value) || value >= min;
  // },

  isString: function isString(value: any) {
    return (
      _isEmpty(value) || typeof value === 'string' || value instanceof String
    );
  },
  minStringLength: function minStringLength(value: string, length: number) {
    return validations.isString(value) && value.length >= length;
  },
  maxStringLength: function maxStringLength(value: string, length: number) {
    return validations.isString(value) && value.length <= length;
  }
};

export default validations;
