import React from 'react';

export interface IValidationInputs {
  overall_ValidationSuccess?: boolean | undefined;
  Validation: {
    Password: {
      MinCharacterLength: boolean | undefined;
      MaxCharacterLength: boolean | undefined;
      NotEmpty: boolean | undefined;
      NumberIncluded: boolean | undefined;
    };
    Email: {
      Format: boolean | undefined;
      NotEmpty: boolean | undefined;
    };
    PhoneNumber: {
      MinCharacterLength: boolean | undefined;
      MaxCharacterLength: boolean | undefined;
      isNumber: boolean | undefined;
      NotEmpty: boolean | undefined;
    };
  };
}

export const ValidateOverallForm = (
  validationInputs: IValidationInputs
): boolean => {
  for (const value of Object.values(validationInputs.Validation)) {
    for (const v of Object.values(value)) {
      if (v === false) return false;
    }
  }
  return true;
};

export const ValidationText = (props: {
  validationState: boolean | undefined;
  text: string;
  invalidColor?: string;
}) => {
  if (props.validationState === false) {
    return (
      <small
        style={{
          color: props.invalidColor || 'red',
          fontWeight: 'bold',
          display: 'block'
        }}
      >
        {props.text}
      </small>
    );
  } else {
    return <></>;
  }
};
