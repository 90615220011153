import React from 'react';
import { Nav } from 'react-bootstrap';
import './bottomNav.css';

const BottomNav: React.FC = () => {
  return (
    <Nav className="dashboard-bottom-nav-container">
      <Nav.Item>
        <Nav.Link href="https://www.neddieblog.com/about" target="_blank">
          About us
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link href="https://www.neddieblog.com/contact">
          Contact Us
        </Nav.Link>
      </Nav.Item>
      {/* <Nav.Item>
        <Nav.Link href="/about">How It Works</Nav.Link>
      </Nav.Item> */}
      <Nav.Item>
        <Nav.Link href="https://demo.neddie.co/terms-and-conditions#top-of-terms-page">
          TERMS
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link href="https://demo.neddie.co/privacy#top-of-privacy-page">
          Privacy
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
};

export default BottomNav;
