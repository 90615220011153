import React, { useState } from 'react';
import API from '../../../utils/API';
import makeID from '../../../utils/makeID';

import ImageDropperCropper from '../../../pages/nonprofitProfile/ImageDropperCropper/ImageDropperCropper';
import { useDispatch } from 'react-redux';
import { saveUser } from '../../../redux/actions';
import { useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import './index.css';
import { deleteImage, getOriginalFileURLFromCroppedURL, uploadImage } from '../../../utils/S3-ImageHandler';
import UserPreferences from './UserPreferences';
import { IOnImageChangeEvent, IOnImageDeleteEvent } from '../../../types/shared.types';

export interface IUserPreferences {
  ShareMyPrivateInfo: {
    name: boolean;
    gender: boolean;
    age: boolean;
    email: boolean;
    telephone: boolean;
    location: boolean;
  };
  demographic: {
    gender: string;
    age: number;
  };
  address: {
    city: string;
    state: string;
  };
  telephone: string;
  otherGender: string;
}

interface IDonorProfile {
  show: boolean;
  hide: () => void;
}

const DonorProfile: React.FC<IDonorProfile> = (props: any) => {
  const dispatch = useDispatch();
  const reduxUser = useSelector((state: any) => state.signin);

  const [formState, setFormState] = useState({
    firstName: reduxUser.firstName,
    lastName: reduxUser.lastName,
    organization_company: reduxUser.organization_company,
    profileImg: reduxUser.profileImg,
    _id: reduxUser._id
  });
  const [userPreferences, setUserPreferences] = useState<IUserPreferences>();
  const [UserValidations, setUserValidation] = useState(true);
  const [newImageUpload, setNewImageUpload] = useState<IOnImageChangeEvent>();
  const [imageDelete, setImageDelete] = useState<IOnImageDeleteEvent>();

  const handleChange = (e: any) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    //return if uservalidation is not true
    if (UserValidations === false) {
      return;
    }
    let newFormState: any = { ...formState };

    if (userPreferences !== undefined) {
      newFormState = {
        ...newFormState,
        ShareMyPrivateInfo: userPreferences.ShareMyPrivateInfo,
        demographic: userPreferences.demographic,
        address: userPreferences.address,
        telephone: userPreferences.telephone
      };
    }
    //upload te profile imag to S3 bucket if th user changed it and then add the new profile image URL to the formState
    if (newImageUpload !== undefined) {
      const newImageURL = await uploadImage(
        newImageUpload.croppedImage,
        newImageUpload.croppedImageFileName,
        formState.profileImg
      );

      newImageURL && (newFormState.profileImg = newImageURL);

      if (newImageUpload.originalImage && newImageUpload.originalImageFileName) {
        await uploadImage(
          newImageUpload.originalImage,
          newImageUpload.originalImageFileName,
          getOriginalFileURLFromCroppedURL(formState.profileImg) 
        );
      }
    }

    if (imageDelete) {
      deleteImage(formState.profileImg)
      .then((response: any) => { console.log('deleted', formState.profileImg) })
      .catch((err: any) => console.error(err));

      newFormState.profileImg = '';

      deleteImage(getOriginalFileURLFromCroppedURL(formState.profileImg))
      .then((response: any) => { console.log('deleted', getOriginalFileURLFromCroppedURL(formState.profileImg)) })
      .catch((err: any) => console.error(err));
    } 

    const res: any = await API.updateUser(newFormState).catch((err: any) =>
      console.log(err)
    );

    if (res.status === 200) {
      dispatch(
        saveUser({
          ...reduxUser,
          ...formState,
          profileImg: newFormState.profileImg
        })
      );
      props.hide();
    }
  };

  //When the user preferences chanages we update the formState and the user Preference State
  const handleUserPreferences = (updatedPreferences: IUserPreferences) => {
    setUserPreferences({ ...updatedPreferences });
    setFormState({ ...formState });
  };

  const handleImageChange = async (event: IOnImageChangeEvent) => {
    setNewImageUpload(event);
    setImageDelete(undefined);
  };

  const handleImageDelete = async (event: IOnImageDeleteEvent) => {
    setImageDelete(event);
    setNewImageUpload(undefined);
  };

  const fetchPreferences = () => {
    //fetch the preferences
    API.fetchUserPreferences(reduxUser._id)
      .then((response: any) => {
        if (response !== undefined) {
          const newPreferences: IUserPreferences = {
            ShareMyPrivateInfo: response.data.ShareMyPrivateInfo,
            demographic: response.data.demographic,
            address: response.data.address,
            telephone: response.data.telephone,
            otherGender:
              response.data.demographic.gender === 'male' ||
              response.data.demographic.gender === 'female' ||
              response.data.demographic.gender === 'non-binary' ||
              response.data.demographic.gender === 'other'
                ? ''
                : response.data.demographic.gender
          };
          setUserPreferences(newPreferences);
          setFormState({ ...formState });
        }
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  return (
    <Modal
      show={props.show}
      onHide={props.hide}
      onEnter={fetchPreferences}
      className='donor-profile-modal-wrapper'
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div className='donor-modal-title'>Edit My Profile</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='donor-profile-modal'>
        <Form className='donor-profile-modal-input'>
          <Form.Group>
            <Form.Label>Profile Image</Form.Label>
            <ImageDropperCropper
              defaultThumbNail={reduxUser.profileImg}
              field={'profileImg'}
              aspect='squareCrop'
              fileName={`prof-pic-${reduxUser._id + makeID(15)}`}
              onImageChange={handleImageChange}
              onImageDelete={handleImageDelete}
            ></ImageDropperCropper>
          </Form.Group>
          <Form.Group>
            <Form.Label>First Name</Form.Label>
            <Form.Control
              value={formState.firstName}
              name='firstName'
              type='text'
              placeholder='first name'
              className='donor-profile-modal-input'
              onChange={(e: any) => handleChange(e)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              value={formState.lastName}
              name='lastName'
              type='text'
              placeholder='last name'
              className='donor-profile-modal-input'
              onChange={(e: any) => handleChange(e)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Organization/Company</Form.Label>
            <Form.Control
              value={formState.organization_company}
              name='organization_company'
              type='text'
              placeholder='Organization/Company'
              className='donor-profile-modal-input'
              onChange={(e: any) => handleChange(e)}
            />
          </Form.Group>
          {!userPreferences ? (
            <h5 className='text-center mt-3'>LOADING PREFERENCES...</h5>
          ) : (
            <UserPreferences
              preferences={userPreferences}
              setUserPreferences={handleUserPreferences}
              userName={reduxUser.firstName + ' ' + reduxUser.lastName}
              email={reduxUser.email}
              setUserValidation={setUserValidation}
            />
          )}

          <Button
            type='submit'
            className={`donor-profile-modal-save donor-profile-modal${
              UserValidations === false ? '-unactiveBtn' : '-activeBtn'
            }`}
            onClick={(e: any) => handleSubmit(e)}
            disabled={!UserValidations}
          >
            Save
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default DonorProfile;