import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface IGiveLocation {
  setPath: (path: any) => void;
}

const GiveLocation: React.FC<IGiveLocation> = ({ setPath }) => {
  const location = useLocation();
  useEffect(() => {
    setPath(location.pathname);
    console.log('--->>>', location.pathname);
  }, [location]);

  return <></>;
};

export default GiveLocation;
