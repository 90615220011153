import React, { useEffect, useState } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import DesktopInstallPopup from './desktopInstallPopup';
import { useSelector } from 'react-redux';
import windows from '../../images/daskboard/window.png'
import one from '../../images/daskboard/1.png'
import eight from '../../images/daskboard/8.png'
import twelve from '../../images/daskboard/12.png'
import thirteen from '../../images/daskboard/13.png'
import fourteen from '../../images/daskboard/14.png'
import fifteen from '../../images/daskboard/15.png'
import './dashboard.css';
// import AcountMobile from './dashboardSetting/AccountMobile';
// import PasswordMobile from './dashboardSetting/PasswordMobile';
import DashboardMorePopup from './dashboardSetting/DashboardMorePopup';
import DashboardSettingIndividula from './dashboardSetting/DashboardSettingIndividula';
import DonorGeneral from '../../components/navbar/donorSettingModal/DonorGeneral';
import DonorPassword from '../../components/navbar/donorSettingModal/DonorPassword';
import DonorProfile from '../../components/navbar/donorModals/DonorProfile';
import defaultLogo from '../../images/Profile_avatar.png';
import BottomNav from './bottomNav/DashboardBottomNav';
import MobileMorePopup from './dashboardSetting/MobileMorePopup';
import { Link } from 'react-router-dom';

const DasgboardIndividual: React.FC = (props: any) => {
  const [daskboardMorePopup, setDaskboardMorePopup] = useState(false);
  const [mobileMorePopup, setMobileMorePopup] = useState(false);
  const [mobileSettingTab, setMobileSettingTab] = useState(0);
  const [dashboardSetting, setDashboardSetting] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const redux = useSelector((state: any) => state);
  const userID = redux.signin?._id;


  const [desktopInstallMessage, setDesktopInstallMessage] = useState(false);
  const reduxInstallPrompt = useSelector(
    (state: any) => state.displayInstallPopup
  );
  useEffect(() => {
    const isDesktop = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();

      return /Mozilla5.0 (Linux; Android 8.0; Pixel 2 Build|OPD3.170816.012) AppleWebKit|537.36 (KHTML, like Gecko) Chrome|86.0.4240.198 Mobile Safari|537.36/.test(
        userAgent
      );
    };
    const newNavigator: any = window.navigator;
    const isInStandaloneMode = () => newNavigator.standalone;

    if (isDesktop() && !isInStandaloneMode() && !(reduxInstallPrompt % 5)) {
      setDesktopInstallMessage(true);
    }
  }, []);




  return (
    <>
      <BrowserView style={{ height: '100%' }}>
        <div className="new-dashboard-layout" style={{ padding: 0 }}>
          <div className="mobile-dashboardd" style={{ backgroundColor: mobileSettingTab === 0 ? 'rgb(72, 116, 205)' : '#fff', minHeight: 'calc(100vh - 77.38px)' }}>
            <div className="dashboard-sec-1" style={{ padding: '15px 45px 10px 45px' }}>
              <div className="top" style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                <div>
                  <img
                    id=''
                    style={{ height: '65px', width: '65px', borderRadius: '50%' }}
                    src={redux?.signin?.profileImg ? redux?.signin?.profileImg : defaultLogo}
                  ></img>
                </div>
                <div>
                  <div className="title">
                    <h1>{redux?.signin?.firstName + " " + redux?.signin?.lastName} </h1>
                  </div>
                  <div className="dashboard">
                    <img src={windows} alt="" />
                    <span>Dashboard</span>
                  </div>
                </div>
              </div>
              <div className="bottom">
                <p className='need-help' style={{ margin: 0 }}>
                  <a href="https://www.neddieblog.com/faqs" target='_blank'>
                    <p >Need Help?</p>
                  </a>
                  <span>i</span>
                </p>
              </div>
            </div>
            <div className="container p-0" style={{ maxWidth: '1270px' }}>
              <div className="dsahboard-sec-3">
                <div className="sec-2-wrap" style={{
                  height: 'calc(100vh - 220px)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                  <div className="row">
                    <div className="col-8 mx-auto">
                      <div className="row">
                        <div className="col-4 px-3 my-3">
                          <div className='div' style={{ cursor: 'pointer' }}>
                            <Link to={'/'}>
                              <img src={twelve} alt="" style={{ width: '55px' }} />
                              <p style={{ fontSize: 12 }}>Home Page</p>
                            </Link>
                          </div >
                        </div>
                        <div className="col-4 px-3 my-3">
                          <div className='div' style={{ cursor: 'pointer' }}>
                            <Link to={'/search'}>
                              <img src={thirteen} alt="" style={{ width: '48px' }} />
                              <p style={{ fontSize: 12 }}>Search Charities</p>
                            </Link>
                          </div >
                        </div>
                        <div className="col-4 px-3 my-3">
                          <div className='div' style={{ cursor: 'pointer' }}>
                            <Link to={`/donor/${userID}`}>
                              <img src={fourteen} alt="" style={{ width: '63px' }} />
                              <p style={{ fontSize: 12 }}>Donation History</p>
                            </Link>
                          </div >
                        </div>
                        <div className="col-4 px-3 my-3" >
                          <div className='div' style={{ cursor: 'pointer' }} onClick={() => { setShowProfileModal(true) }}>
                            <img src={one} alt="" style={{ width: '45px' }} />
                            <p style={{ fontSize: 12 }}>Edit My Profile</p>
                          </div >
                        </div>
                        <div className="col-4 px-3 my-3">
                          <div className='div' style={{ cursor: 'pointer' }} onClick={() => { setDashboardSetting(true) }}>
                            <img src={eight} alt="" style={{ width: '43px' }} />
                            <p style={{ fontSize: 12 }}>Account Settings</p>
                          </div>
                        </div>
                        <div className="col-4 px-3 my-3">
                          <div className='div' style={{ cursor: 'pointer' }} onClick={() => { setDaskboardMorePopup(true) }}>
                            <img src={fifteen} alt="" style={{ width: '48px' }} />
                            <p style={{ fontSize: 12 }}>More+</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="sec-2-wrap">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-7">
                      <div className="row">
                        <div className="col-lg-4">
                          <div onClick={() => { setDashboardSetting(true) }}>
                            <img src={eight} alt="" />
                            <p>Account Settings</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="dashboard-footer">
          <div className="dashboard-copyright">
            <p>© {new Date().getFullYear()} Neddie, all rights reserved</p>
          </div>

          <div className="dashboard-bootom-nav">
            <BottomNav />
          </div>
        </div>
        {desktopInstallMessage && (
          <DesktopInstallPopup close={() => setDesktopInstallMessage(false)} />
        )}
      </BrowserView>
      <MobileView>
        {/* <MobileDashboard /> */}
        <div className="mobile-dashboard" style={{ backgroundColor: mobileSettingTab === 0 ? 'rgb(72, 116, 205)' : '#fff', minHeight: 'calc(100vh - 58.5px)' }}>
          <div className="dashboard-sec-1">
            <div className="top">
              <div style={{display : 'flex' , gap : '10px' , alignItems : 'center'}}>
              <div>
                <img
                  id=''
                  style={{ height: '50px', width: '50px', borderRadius: '50%' }}
                  src={redux?.signin?.profileImg ? redux?.signin?.profileImg : defaultLogo}
                ></img>
              </div>
              <div>
                <div className="title">
                  <h1>{redux?.signin?.firstName + " " + redux?.signin?.lastName} </h1>
                </div>
                <div className="dashboard">
                  <img src={windows} alt="" />
                  <span>Dashboard</span>
                </div>
              </div>
              </div>
            </div>
            <div className="bottom">
              <p className='need-help'>
                <a href="https://www.neddieblog.com/faqs" target='_blank'>
                  <p >Need Help?</p>
                </a>
                <span>i</span>
              </p>
            </div>
            <div className="cricle-1"></div>
            <div className="cricle-2"></div>
          </div>

          {mobileSettingTab === 0 &&
            <>
              <div className="dashboard-sec-2">
                <p>
                  Welcome to your NEDDIE dashboard!
                  <div className="cricle-1"></div>
                  <div className="cricle-2"></div>
                  <div className="cricle-3"></div>
                  <div className="cricle-4"></div>
                  <div className="cricle-5"></div>
                </p>
              </div>
              <div className="dsahboard-sec-3" >
                <div className="sec-2-wrap">
                  <div className="row">
                    <div className="col-4 px-2 mb-3">
                      <div >
                        <Link to={'/'}>
                          <img src={twelve} alt="" style={{ width: '40px' }} />
                          <p>Home Page</p>
                        </Link>
                      </div >
                    </div>
                    <div className="col-4 px-2 mb-3">
                      <div>
                        <Link to={'/search'}>
                          <img src={thirteen} alt="" style={{ width: '35px' }} />
                          <p>Search Charities</p>
                        </Link>
                      </div >
                    </div>
                    <div className="col-4 px-2 mb-3">
                      <div >
                        <Link to={`/donor/${userID}`}>
                          <img src={fourteen} alt="" style={{ width: '45px' }} />
                          <p>Donation History</p>
                        </Link>
                      </div >
                    </div>
                    <div className="col-4 px-2 mb-3" >
                      <div onClick={() => { setShowProfileModal(true) }}>
                        <img src={one} alt="" style={{ width: '35px' }} />
                        <p>Edit My Profile</p>
                      </div >
                    </div>
                    <div className="col-4 px-2 mb-3">
                      <div onClick={() => { setDashboardSetting(true) }}>
                        <img src={eight} alt="" style={{ width: '33px' }} />
                        <p>Account Settings</p>
                      </div>
                    </div>
                    <div className="col-4 px-2 mb-3">
                      <div onClick={() => { setMobileMorePopup(true) }}>
                        <img src={fifteen} alt="" style={{ width: '38px' }} />
                        <p>More+</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          }
          {mobileSettingTab === 1 &&
            <>
              <DonorGeneral setMobileSettingTab={setMobileSettingTab} />
            </>
          }
          {mobileSettingTab === 2 &&
            <>
              <DonorPassword setMobileSettingTab={setMobileSettingTab} />
            </>
          }
        </div>
      </MobileView >
      <MobileMorePopup daskboardMorePopup={mobileMorePopup} setDaskboardMorePopup={setMobileMorePopup} />
      <DashboardMorePopup daskboardMorePopup={daskboardMorePopup} setDaskboardMorePopup={setDaskboardMorePopup} />
      <DashboardSettingIndividula dashboardSetting={dashboardSetting} setDashboardSetting={setDashboardSetting} mobileSettingTab={mobileSettingTab} setMobileSettingTab={setMobileSettingTab} />
      <DonorProfile
        show={showProfileModal}
        hide={() => setShowProfileModal(false)}
      />

    </>
  );
};

export default DasgboardIndividual;
