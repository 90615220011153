import React, { useState, useEffect, useRef } from 'react';
// @ts-ignore
import downArrow from '../../images/down-arrow.js';
import downArroww from '../../images/down-arrow.png';
import burger from '../../images/hamburger.svg';
import defaultLogo from '../../images/Profile_avatar.png';
import { useSelector } from 'react-redux';

interface NavDropProps {
  title: string;
  icon: 'down' | 'burger' | 'logo';
  style?: string;
  width?: string;
}

const NavDrop: React.FC<NavDropProps> = (props) => {
  const [showDrop, setShowDrop] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const reduxUser = useSelector((state: any) => state.signin);
  const reduxOrg = useSelector((state: any) => state.org);

  const clickOutside = (e: any) => {
    // check if we've clicked outside of the menu
    const { clientX, clientY } = e;
    const element = document.elementFromPoint(clientX, clientY);
    const children: Array<Element> = [];
    if (ref.current) {
      for (let i = 0; i < ref.current.children.length; ++i) {
        children.push(ref.current.children[i]);
      }
    }
    if (
      ref.current &&
      element &&
      showDrop &&
      element !== ref.current &&
      !children.includes(element)
    ) {
      setShowDrop(false);
    }
  };
  const showDropHandler = () => {
    setShowDrop(!showDrop);
  };

  useEffect(() => {
    window.addEventListener('click', clickOutside);
    return () => window.removeEventListener('click', clickOutside);
  }, [showDrop]);

  if (props.icon === 'burger') {
    return (
      <div className='drop-wrapper'>
        
        <span className='nav-drop ' ref={ref} onClick={showDropHandler}>
          <img id='burger' src={burger}></img>
        </span>
        {showDrop && (
          <div className='nav-drop-links nav-burger-links'>
            {props.children}
          </div>
        )}
      </div>
    );
  }

  const logoSrc =
    reduxUser.type === 'individual'
      ? reduxUser.profileImg
      : reduxOrg.images?.logo;
  if (props.icon === 'logo') {
    return (
      <div className='drop-wrapper' title="User Profile">
        
        <span className='nav-drop' ref={ref} onClick={showDropHandler}>
        
          <img 
            id='navbar-loggedin-logo'
            src={logoSrc ? logoSrc : defaultLogo}
          ></img>
          <img className='arrow-down-header' src={downArroww} alt="" />
        </span>
        {showDrop && (
          <div className='nav-drop-links nav-logged-links'>
            {props.children}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className='drop-wrapper'>
      <span className='nav-drop' ref={ref} onClick={showDropHandler}>
        {props.title} {props.icon === 'down' && downArrow}
      </span>
      {showDrop && <div className='nav-drop-links'>{props.children}</div>}
    </div>
  );
};

export default NavDrop;
