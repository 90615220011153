import React from 'react';
import './index.css';
import { Row, Col } from 'react-bootstrap';

const BottomFooter = () => {
  return (
    <div className="footerContainer container">
      <Row id="bottomFooter">
        <Col xs={12} md={12} className="text-right">
          <span id="copyright" className="font-weight-light">
            {' '}
            &copy; {new Date().getFullYear()} NEDDIE, all rights reserved{' '}
          </span>
        </Col>
      </Row>
    </div>
  );
};

export default BottomFooter;
