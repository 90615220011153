import React from 'react';
import './index.css';
import {
  Row,
  Col
} from 'react-bootstrap';
// import API from '../../utils/API';
// import { Link } from 'react-router-dom';
// import goUpBtn from '../../images/Icon/goUpArrow.svg';
// import { Link } from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faLinkedin,
  faInstagram,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';

export interface IEmail {
  email?: string;
}
interface TopFooterProps {
  handleSubmit?: () => void;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const TopFooter: React.FC<TopFooterProps> = () => {
  // const [emailInput, setEmailInput] = useState<IEmail>({});
  // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setEmailInput({ email: e.currentTarget.value });
  // };
  // const handleSubmit = (e: React.SyntheticEvent) => {
  //   e.preventDefault();
  //   API.subscribe(emailInput)
  //     .then((res: any) => {
  //       alert(res.data.msg);
  //       setEmailInput({ email: '' });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       alert('Oops, something went wrong. Try again');
  //     });
  // };
  return (
    <div className="footerContainer">
      <div id="topFooter">
        {/* <div className="mb-5">
          <h4 className="text-center text-dark mb-2">
            Subscribe to follow NEDDIE
          </h4>
          <InputGroup id="subscribeEmail">
            <FormControl
              placeholder="Email Address"
              type="email"
              aria-label="Email Address"
              id="emailForm"
              value={emailInput.email || ''}
              onChange={handleChange}
            />
            <InputGroup.Append>
              <Button
                className="top-footer-subscribe-button"
                variant="outline-secondary"
                type="submit"
                id="subscribe"
                onClick={handleSubmit}
                data-testid="subscribe"
              >
                Subscribe
              </Button>
            </InputGroup.Append>
          </InputGroup>
        </div> */}
        <hr />
        <Row className="p-3 top-footer-links">
          <Col className="top-footer-text-links">
            <Row className="topFooter-links">
              <Col>
                <a href="https://www.neddieblog.com/about" target="_blank">
                  About Us
                </a>
              </Col>
              <Col>
                <a href="https://www.neddieblog.com/contact" target="_blank">
                  Contact Us
                </a>
              </Col>
              <Col>
                <a href="https://www.neddieblog.com/grant-central-plans-pricing" target="_blank">Plans and Pricing</a>
                {/* <a href="/about">How It Works</a> */}
              </Col>
              <Col>
                <a href="https://demo.neddie.co/terms-and-conditions#top-of-terms-page">
                  Terms
                </a>
              </Col>
              <Col>
                <a href="https://demo.neddie.co/privacy#top-of-privacy-page">
                  Privacy
                </a>
              </Col>
            </Row>
            <Col className="social-links-footer">
              <a
                href="https://www.facebook.com/neddieco"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faFacebook} className="social" />
              </a>
              <a
                href="https://www.linkedin.com/company/neddie"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedin} className="social" />
              </a>
              <a
                href="https://www.instagram.com/neddie.co"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} className="social" />
              </a>
              <a
                href="https://twitter.com/NEDDIE_CO"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faTwitter} className="social" />{' '}
              </a>
            </Col>
          </Col>
        </Row>
        <hr />
      </div>
    </div>
  );
};
export default TopFooter;
