import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import eight from '../../../images/daskboard/8.png'
import './dashboardSetting.css'
import { FaEnvelope, FaLock, FaUserAlt } from 'react-icons/fa';
import Acount from './Acount';
import Password from './Password';
import Help from './Help';
import ContactUs from './ContactUs';

interface ISharingOption {
  dashboardSetting?: boolean; //to make the share symbol into a Neddie Button
  setDashboardSetting?: (value: boolean) => void; //to make the share button light
  mobileSettingTab?: number; //to make the share symbol into a Neddie Button
  setMobileSettingTab?: (value: number) => void; //to make the share button light
}

const DashboardSetting: React.FC<ISharingOption> = ({ setDashboardSetting, dashboardSetting  , mobileSettingTab , setMobileSettingTab}) => {
  const [activeTab, setActiveTab] = useState(0)
  const handleSetWishList = () => {
    if (setDashboardSetting) {
      setDashboardSetting(!dashboardSetting);
    }
  };
  const handleMobileSetting = (index: number) => {
    if (setMobileSettingTab) {
      setMobileSettingTab(index);
    }
  };
  return (
    <Modal centered show={dashboardSetting} onHide={() =>{handleMobileSetting(0); handleSetWishList()}} className='dashboard-setting-popups'>
      <div id='hide-on-mobile'>
        <div className='manage-wish-wrap' >
          <span className='cancle-popup' onClick={() =>{handleMobileSetting(0); handleSetWishList()}}>x</span>
          <div className="left">
            <h1 className='manage-marketplace-h2'>Account Settings <img src={eight} style={{ width: '22px', marginTop: '-0px' }} alt="" /></h1>
            <div className="tab-listing">
              <ul>
                <li onClick={() => setActiveTab(0)} className={`${activeTab === 0 ? 'active' : ''}`}><span><FaUserAlt /></span>Account</li>
                <li onClick={() => setActiveTab(1)} className={`${activeTab === 1 ? 'active' : ''}`}><span><FaLock /></span>Password</li>
                <li> <a href="https://www.neddieblog.com/get-help" target='_blank'><span>!</span>Help</a></li>
                <li> <a href="https://www.neddieblog.com/contact" target='_blank'><span><FaEnvelope /></span>Contact Us</a></li>
              </ul>
            </div>
          </div>
          <div className="center"></div>
          <div className="right">
            {activeTab === 0 && <Acount />}
            {activeTab === 1 && <Password />}
            {activeTab === 3 && <Help />}
            {activeTab === 4 && <ContactUs />}

          </div>


        </div>
      </div>
      <div id='hide-on-desktop'>
      <div className='manage-wish-wrap' id='hide-on-desktop'>
        <span className='cancle-popup' onClick={() =>{handleMobileSetting(0); handleSetWishList()}}>x</span>
        <div className="left">
          <h1 className='manage-marketplace-h2'>Account Settings <img src={eight} style={{ width: '22px', marginTop: '-0px' }} alt="" /></h1>
          <div className="tab-listing">
            <ul>
              <li onClick={() =>{handleMobileSetting(1); handleSetWishList()}} className={`${activeTab === 0 ? 'active' : ''}`}><span><FaUserAlt /></span>Account</li>
              <li onClick={() =>{handleMobileSetting(2); handleSetWishList()}} className={`${activeTab === 1 ? 'active' : ''}`}><span><FaLock /></span>Password</li>
              <li> <a href="https://www.neddieblog.com/get-help" target='_blank'><span>!</span>Help</a></li>
              <li> <a href="https://www.neddieblog.com/contact" target='_blank'><span><FaEnvelope /></span>Contact Us</a></li>
            </ul>
          </div>
        </div>
      </div>
      </div>
    </Modal>
  )
}

export default DashboardSetting