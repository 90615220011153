import React, { useState } from 'react';
// import warning from '../images/icons/warning-icon.svg';

interface IConfirmDeleteAccount {
  cancelDelete?: (e: React.MouseEvent<HTMLElement>) => any;
  setMobileSettingTab?: (value: number) => void; //to make the share button light
}

const ConfirmDeleteAccount: React.FC<IConfirmDeleteAccount> = ({
  cancelDelete,
  setMobileSettingTab
}) => {

  const isChecked = false;
  // const [isChecked, setChecked] = useState(true);
  const [isDeleted, setDeleted] = useState(false);
  const [goodBye, setGoodBye] = useState(false);

  // checkbox checked or not
  // const handleChange = () => {
  //   if (isChecked) {
  //     setChecked(false);
  //   } else {
  //     setChecked(true);
  //   }
  // };
  // enables success deleted popup... needs actual delete from DB
  const handleDelete = () => {
    // if (isChecked) {
      setDeleted(!isChecked);
      setGoodBye(true)
    // } else {
    //   return;
    // }
  };

  const handleMobileSetting = (index: number) => {
    if (setMobileSettingTab) {
      setMobileSettingTab(index);
    }
  };

  return (
    <div className='settings-confirm-delete-container'>
      <div className='settings-confirm-delete-content'>
        {/* {!isDeleted ? ( */}
          {goodBye && 
          <div className='settings-confirm-delete-content-inner'>
            {/* <p
              style={{
                fontSize: '2.5vw',
                color: '#406dc9',
                marginTop: '4vw',
                marginBottom: '2vw',
                fontWeight: 'bold'
              }}
            >
              NEDDIE
            </p> */}
            <h1 className='goodbye'>We are sorry to see you go. We hope you can join our community again.</h1>
            <div className="buttonn">
              <button onClick={() =>{setGoodBye(false)}}>Close</button>
            </div>
            {/* <div className='settings-confirm-delete-content-inner-p'>
              <p style={{ fontSize: '1.25vw' }}>
                It's sad to see you go, if you have any suggestions on how we
                can make the NEDDIE experience better for you please contact us
                at <a>contact@neddie.co</a>
              </p>
              <p style={{ fontWeight: 'bold', fontSize: '1.25vw' }}>
                See you later!
              </p>
            </div> */}
          </div>
          }
        {/* ) : ( */}
          <>
          {!isDeleted &&
            <div className='settings-confirm-delete-content-inner'>
              {/* <div className='settings-confirm-delete-warning-container'>
                <img
                  className='settings-confirm-delete-warning-icon'
                  src={warning}
                  alt='warning'
                />
                <h6>Are you sure?</h6>
              </div> */}
              {/* <div className='settings-confirm-delete-content-inner-p'>
                <p style={{ fontWeight: 'bold' }}>
                  Deleting your account will:{' '}
                </p>
                <p>Delete your account from NEDDIE.</p>
                <p>Erase your event history.</p>
                <p>Delete all of the donate history.</p>
              </div> */}

              {/* <div
                style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
              >
                <form action=''>
                  <input
                    type='checkbox'
                    id='confirm'
                    name='confirm'
                    value='Confirm'
                    onChange={handleChange}
                    style={{ height: '1vw', width: '1vw' }}
                  />
                  <label>
                    {' '}
                    &nbsp;Yes, I understand. Please delete my account.{' '}
                  </label>
                </form>
              </div> */}
              <h1 className='confrom-title'>Are you sure you want to delete your account?</h1>
              <div className='settings-confirm-delete-btn-container'>
                
                <button
                  className='settings-confirm-delete-btn'
                  onClick={() => {handleDelete() ; handleMobileSetting(0)}}
                >
                  Yes
                </button>
                <button
                  className='settings-confirm-keep-btn'
                  onClick={cancelDelete}
                >
                  No
                </button>
              </div>
            </div>
          }
          </>
        {/* )} */}
      </div>
    </div>
  );
};
export default ConfirmDeleteAccount;
