const signinUserReducer = (state = {}, action: any) => {
  switch (action.type) {
    case 'signin/saveUser':
      return action.payload;
    case 'signin/deleteUser':
      return {};
    default:
      return state;
  }
};

export default signinUserReducer;
