//@ts-nocheck

import './index.css';

import { Link, useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { deleteOrg, deleteUser, saveUser } from '../../redux/actions';

import DonorProfile from './donorModals/DonorProfile';
import DonorSettingModal from './donorSettingModal/donorSetting';
import NavDropDown from './navDropDown';
import NeddieButton from '../../components/neddieButton';
import SearchBar from '../searchbar';
import { isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import menuicon from './images/menu-icon.svg'
// import cross from './images/cross.png'
import cross from './images/cross.svg'
import magnifying from './images/magnifying.svg'
import { useLocation } from 'react-router-dom';

interface ContainerProps {
  class: string;
  background?: 'white' | 'trans';
}
const windowWidth = window.innerWidth;

const Navtab: React.FC<ContainerProps> = (props) => {
  const dispatch = useDispatch();
  const reduxState = useSelector((state) => state);
  const history = useHistory();
  const path = history.location.pathname;
  const location = useLocation();
  const currentPath = location.pathname;
  console.log(currentPath, 'currentPath');


  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showSettingModal, setShowSettingModal] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(true);
  const [showSearch, setShowSearch] = useState(true);
  const [one, setOne] = useState(false);
  const [two, setTwo] = useState(false);
  const [three, setThree] = useState(false);
  const [four, setFour] = useState(false);
  const [five, setFive] = useState(false);

  const [style, setStyle] = useState(
    window.pageYOffset <= 50 && props.background === 'trans' ? 'trans' : 'white'
  );
  const [width, setWidth] = useState(window.innerWidth);
  // console.log(reduxState);

  let logged = 'loggedOut';
  if (reduxState.signin?.type) {
    logged = reduxState.signin.type;
  }

  const orgID = reduxState.org?._id;

  const userID = reduxState.signin?._id;

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  const logout = () => {
    dispatch(deleteUser());
    dispatch(deleteOrg());
    history.push('/');
  };

  const burgerOrNot = () => {
    if (width > 800) {
      return (
        <>

          {logged === 'nonprofit' && (
            <NavDropDown title="" icon="logo">
              <Link to={`/nonprofit/${orgID}`}>Public Profile</Link>
              <Link to="/dashboard">Dashboard</Link>
              <a href="https://www.neddieblog.com/grant-central-search">Search For Grants</a>
              <a href="https://www.neddieblog.com/neddie-forum">Community Forum</a>
              <Link to='/dashboard/settings'>Settings</Link>
              <span onClick={() => { logout() }}>Log Out</span>
            </NavDropDown>
          )}
          {logged === 'individual' && (
            <NavDropDown title="" icon="logo">
              <Link to={`/dashboard`}>Dashboard</Link>
              <span onClick={() => setShowSettingModal(true)}>Settings</span>
              <a href="https://www.neddieblog.com/contact">Contact Us</a>
              <span onClick={() => { logout() }}>Log Out</span>
            </NavDropDown>
          )}
        </>
      );
    } else {
      // mobile screen
      if (logged === 'individual') {
        return( <>
        <div>
          <>
            <div>
              <div onClick={() => { setShowMobileMenu(!showMobileMenu) }}>
                <img className='menu-con-svg' src={menuicon} alt="" />
              </div>
            </div>
            <div className={`showwheader ${showMobileMenu ? '' : 'hideMobileMenu'}`}>
              <header className='o-drawer-header global-wrapper pr0'>
                <button onClick={() => { setShowMobileMenu(!showMobileMenu) }}
                  className='o-drawer-header-close-button hrt-tertiary-icon-button hrt-tertiary-icon-button--medium hrt-base-button'>
                  <img src={cross} alt="" />
                </button>
                <div class="o-drawer-header-content"></div>
              </header>
              <div className='o-drawer-content global-wrapper'>
                <div id='dh-mobile-menu'>
                  <ul className='list-unstyled'>
                    <div className='mb2x' onClick={() => { setShowMobileMenu(!showMobileMenu) }}>
                      <Link to="/">NEDDIE</Link>
                    </div>
                    <li>
                      <ul className='list-unstyled hrt-accordion-menu'>
                        <li className='simple-linkk' onClick={() => { setShowMobileMenu(!showMobileMenu) }}>
                          <Link to="/dashboard">Dashboard</Link>
                        </li>
                        <li className='simple-linkk' onClick={() => {setShowSettingModal(true); setShowMobileMenu(!showMobileMenu) }}>
                          <Link to='/dashboard/settings'>Settings</Link>
                        </li>
                        <li className='simple-linkk' onClick={() => { setShowMobileMenu(!showMobileMenu) }}>
                          <a href="https://www.neddieblog.com/contact"> Contact Us</a>
                        </li>
                        <li onClick={() => { setShowMobileMenu(!showMobileMenu) }} className='simple-link'>
                          <Link onClick={() => { logout() }} className='sign-up'>Logout</Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </>
        </div>
        </>)
      }
      // default mobile menu
      return (
        <>
          {logged === 'loggedOut' && (
            <>
              <div>
                <div onClick={() => { setShowMobileMenu(!showMobileMenu) }}>
                  <img className='menu-con-svg' src={menuicon} alt="" />
                </div>
              </div>
              <div className={`showwheader ${showMobileMenu ? '' : 'hideMobileMenu'}`}>
                <header className='o-drawer-header global-wrapper pr0'>
                  <button onClick={() => { setShowMobileMenu(!showMobileMenu) }}
                    className='o-drawer-header-close-button hrt-tertiary-icon-button hrt-tertiary-icon-button--medium hrt-base-button'>
                    <img src={cross} alt="" />
                  </button>
                  <div class="o-drawer-header-content"></div>
                </header>
                <div className='o-drawer-content global-wrapper'>
                  <div id='dh-mobile-menu'>
                    <ul className='list-unstyled'>
                      <div className='mb2x' onClick={() => { setShowMobileMenu(!showMobileMenu) }}>
                        <Link to="/">NEDDIE</Link>
                      </div>
                      <li>
                        <ul className='list-unstyled hrt-accordion-menu'>
                          <li
                            onClick={() => {
                              setOne(!one); setTwo(false); setThree(false); setFour(false); setFive(false)
                            }}
                            className={`hrt-accordion-menu-item ${one ? 'Activeone' : ''}`}>
                            <NavDropDown title="Explore" icon="down">
                              <Link to="/search">
                                Charities
                              </Link>
                              {path.split('/')[1] === 'campaigns' || path.split('/')[1] === 'search' ?
                                <a onClick={() => { setShowMobileMenu(!showMobileMenu) }}>Fundraisers</a>
                                : <Link to={{ pathname: '/search', state: { searchCategory: 'Campaigns', }, }}>
                                  Fundraisers
                                </Link>}
                            </NavDropDown>
                          </li>
                          <li
                            onClick={() => {
                              setOne(false); setTwo(!two); setThree(false); setFour(false); setFive(false)
                            }}
                            className={`hrt-accordion-menu-item ${two ? 'Activetwo' : ''}`}>
                            <NavDropDown title="For Donors" icon="down">
                              <a href="https://www.neddieblog.com/neddie-for-donors">How It Works</a>
                              {path.split('/')[1] === 'campaigns' || path.split('/')[1] === 'search' ?
                                <a onClick={() => { setShowMobileMenu(!showMobileMenu) }}>Fundraisers</a>
                                : <Link to={{ pathname: '/search', state: { searchCategory: 'Campaigns', }, }}>
                                  Fundraisers
                                </Link>}
                              <a href="https://www.neddieblog.com/purpose-match">Purpose Match</a>
                              <a href="https://www.neddieblog.com/faqs">FAQs</a>
                            </NavDropDown>
                          </li>
                          <li
                            onClick={() => {
                              setOne(false); setTwo(false); setThree(!three); setFour(false); setFive(false)
                            }}
                            className={`hrt-accordion-menu-item ${three ? 'Activethree' : ''}`}>
                            <NavDropDown title="For Charities" icon="down">
                              <a href="https://www.neddieblog.com/neddie-for-charities">How It Works</a>
                              <a href="https://www.neddieblog.com/grant-central-faqs">Grant Central</a>
                              <a href="https://www.neddieblog.com/plans-pricing">Plans & Pricing</a>
                              <a href="https://www.neddieblog.com/neddie-forum">Community Forum</a>
                              <a href="https://www.neddieblog.com/influencers-for-good">Influencers For Good</a>
                              <a href="https://www.neddieblog.com/faqs">FAQS</a>
                            </NavDropDown>
                          </li>
                          <li
                            onClick={() => {
                              setOne(false); setTwo(false); setThree(false); setFour(!four); setFive(false)
                            }}
                            className={`hrt-accordion-menu-item ${four ? 'Activefour' : ''}`}>
                            <NavDropDown title="About" icon="down">


                              <a href="https://www.neddieblog.com/about">About Us</a>
                              {/* <a href="https://www.neddieblog.com/vetting-charities">How We Vet Charities</a> */}
                              <a href="https://www.neddieblog.com/testimonials">Testimonials</a>
                            </NavDropDown>
                          </li>
                          <li
                            onClick={() => {
                              setOne(false); setTwo(false); setThree(false); setFour(false); setFive(!five)
                            }}
                            className={`hrt-accordion-menu-item ${five ? 'Activefive' : ''}`}>
                            <NavDropDown title="Resources" icon="down">
                              <a href="https://www.neddieblog.com/">Blog</a>
                              <a href="https://www.neddieblog.com/faqs">FAQs</a>
                              <a href="https://www.neddieblog.com/contact">Contact Us</a>
                            </NavDropDown>
                          </li>
                          <li onClick={() => { setShowMobileMenu(!showMobileMenu) }} className='simple-link'>
                            <Link to="/login" className='loginbutton'>Login</Link>
                          </li>
                          <li onClick={() => { setShowMobileMenu(!showMobileMenu) }} className='simple-link'>
                            <Link to="/signup" className='sign-up'>Sign Up</Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>

              </div>
            </>
          )}
          {logged === 'nonprofit' && (
            <div>
              <>
                <div>
                  <div onClick={() => { setShowMobileMenu(!showMobileMenu) }}>
                    <img className='menu-con-svg' src={menuicon} alt="" />
                  </div>
                </div>
                <div className={`showwheader ${showMobileMenu ? '' : 'hideMobileMenu'}`}>
                  <header className='o-drawer-header global-wrapper pr0'>
                    <button onClick={() => { setShowMobileMenu(!showMobileMenu) }}
                      className='o-drawer-header-close-button hrt-tertiary-icon-button hrt-tertiary-icon-button--medium hrt-base-button'>
                      <img src={cross} alt="" />
                    </button>
                    <div class="o-drawer-header-content"></div>
                  </header>
                  <div className='o-drawer-content global-wrapper'>
                    <div id='dh-mobile-menu'>
                      <ul className='list-unstyled'>
                        <div className='mb2x' onClick={() => { setShowMobileMenu(!showMobileMenu) }}>
                          <Link to="/">NEDDIE</Link>
                        </div>
                        <li>
                          <ul className='list-unstyled hrt-accordion-menu'>
                            <li className='simple-linkk' onClick={() => { setShowMobileMenu(!showMobileMenu) }}>
                              <Link to={`/nonprofit/${orgID}`}>Public Profile</Link>
                            </li>
                            <li className='simple-linkk' onClick={() => { setShowMobileMenu(!showMobileMenu) }}>
                              <Link to="/dashboard">Dashboard</Link>
                            </li>
                            <li className='simple-linkk' onClick={() => { setShowMobileMenu(!showMobileMenu) }}>
                              <a href="https://www.neddieblog.com/grant-central-search">Search For Grants</a>
                            </li>
                            <li className='simple-linkk' onClick={() => { setShowMobileMenu(!showMobileMenu) }}>
                              <a href="https://www.neddieblog.com/neddie-forum">Community Forum</a>
                            </li>
                            <li className='simple-linkk' onClick={() => { setShowMobileMenu(!showMobileMenu) }}>
                              <Link to='/dashboard/settings'>Settings</Link>
                            </li>
                            <li className='simple-linkk' onClick={() => { setShowMobileMenu(!showMobileMenu) }}>
                              <a href="https://www.neddieblog.com/faqs">FAQ</a>
                            </li>

                            <li className='simple-linkk' onClick={() => { setShowMobileMenu(!showMobileMenu) }}>
                              <a href="https://www.neddieblog.com/"> Blog</a>
                            </li>
                            <li className='simple-linkk' onClick={() => { setShowMobileMenu(!showMobileMenu) }}>
                              <a href="https://www.neddieblog.com/contact"> Contact Us</a>
                            </li>
                            <li onClick={() => { setShowMobileMenu(!showMobileMenu) }} className='simple-link'>
                              <Link onClick={() => { logout() }} className='sign-up'>Logout</Link>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </>
            </div>
          )}
        </>

      );
    }
  };

  return (
    <>


      <nav id="navbar">
        <div className="container  p-0">
          <div className="row m-0 p-0">
            <div className={` ${path.split('/')[1] === 'campaigns' || path.split('/')[1] === 'search' ? 'col-lg-2' : 'col-lg-3'} col-1 m-0 p-0 col-md-1`}>
              <span id="navbar-brand"> {width > 800 ?
                <Link to="/" style={style === 'trans' && width > 800 ? { color: 'var(--neddie-blue)' } : {}}>
                  NEDDIE
                </Link> : <div> <img onClick={() => { setShowSearch(!showSearch) }} src={magnifying} alt="" /> </div>}</span>
            </div>
            {width > 800 && logged === 'loggedOut' ?
              <div className={` ${path.split('/')[1] === 'campaigns' || path.split('/')[1] === 'search' ? 'col-lg-7' : 'col-lg-6'} col-md-2 col-3 my-auto left-right`}>
                <div className="row ">
                  {width > 800 ? path.split('/')[1] === 'campaigns' || path.split('/')[1] === 'search' ? ('')
                    : (<div className="col-lg-4 col-sm-12 p-0"><SearchBar /></div>)
                    : <Link to="/">NEDDIE</Link>}


                  {width > 800 ?
                    <div className={` ${path.split('/')[1] === 'campaigns' || path.split('/')[1] === 'search' ? 'desktop-headers-left col-lg-9' : 'col-lg-8'} desktop-header my-auto p-0`}>
                      <NavDropDown className='desktop-header-text' style={style} width={width} title="For Donors" icon="down" >
                        <a href="https://www.neddieblog.com/neddie-for-donors">How It Works</a>
                        {path.split('/')[1] === 'campaigns' || path.split('/')[1] === 'search' ?
                          <a onClick={() => { setShowMobileMenu(!showMobileMenu) }}>Fundraisers</a>
                          : <Link to={{ pathname: '/search', state: { searchCategory: 'Campaigns', }, }}>
                            Fundraisers
                          </Link>}
                        <a href="https://www.neddieblog.com/purpose-match">Purpose Match</a>
                        <a href="https://www.neddieblog.com/">Resources</a>
                        <a href="https://www.neddieblog.com/faqs">FAQ</a>
                        {width > 800 && logged === 'loggedOut' ? <Link to="/login">Login</Link> : ''}
                      </NavDropDown>

                      <NavDropDown className='desktop-header-text' style={style} width={width} title="For Charities" icon="down">
                        <a href="https://www.neddieblog.com/neddie-for-charities">How It Works</a>
                        <a href="https://www.neddieblog.com/grant-central-faqs">Grant Central </a>
                        <a href="https://www.neddieblog.com/grant-central-plans-pricing">Plans & Pricing</a>
                        <a href="https://www.neddieblog.com/neddie-forum">Community Forum</a>
                        <a href="https://www.neddieblog.com/influencers-for-good">Influencers For Good</a>
                        <a href="https://www.neddieblog.com/faqs">FAQS </a><a href="https://www.neddieblog.com/">Resources </a>
                        {width > 800 && logged === 'loggedOut' ? <Link to="/login">Login</Link> : ''}
                      </NavDropDown>

                      <NavDropDown className='desktop-header-text' style={style} width={width} title="About" icon="down">
                        <a href="https://www.neddieblog.com/about">About Us</a>
                        <a href="https://www.neddieblog.com/testimonials">Testimonials</a>
                        <a href="https://www.neddieblog.com/">Blog</a>
                        <a href="https://www.neddieblog.com/faqs">FAQS</a>
                        <a href="https://www.neddieblog.com/contact">Contact Us</a>
                      </NavDropDown>
                    </div>
                    : ''}
                </div>
              </div> : width < 800 && logged === 'loggedOut' && logged !== 'nonprofit' && logged !== 'individual' &&
              <div className="col-lg-7 col-md-2 col-4 my-auto left-right">
                <Link to="/">NEDDIE</Link> </div>}


            {width > 800 && logged === 'individual' ?
              <div className={`${path.split('/')[1] === 'campaigns' || path.split('/')[1] === 'search' ? 'col-lg-10' : 'col-lg-7'} col-md-2 col-3 my-auto left-right`}>
                <div className="row ">
                  {width > 800 ?
                    path.split('/')[1] === 'campaigns' || path.split('/')[1] === 'search' ? ('')
                      : (<div className="col-lg-5 col-sm-12 p-0"><SearchBar /></div>)
                    : <Link to="/">NEDDIE</Link>}


                  {width > 800 ?

                    <div className="col-lg-7 desktop-header desktop-headers my-auto p-0">
                      <NavDropDown className='desktop-header-text' style={style} width={width} title="For Donors" icon="down" >
                        <a href="https://www.neddieblog.com/neddie-for-donors">How It Works</a>
                        {path.split('/')[1] === 'campaigns' || path.split('/')[1] === 'search' ?
                          <a>Fundraisers</a>
                          : <Link to={{ pathname: '/search', state: { searchCategory: 'Campaigns', }, }}>
                            Fundraisers
                          </Link>}
                        <a href="https://www.neddieblog.com/purpose-match">Purpose Match</a>
                        <a href="https://www.neddieblog.com/">Resources</a>
                        <a href="https://www.neddieblog.com/faqs">FAQ</a>
                        {width > 800 && logged === 'loggedOut' ? <Link to="/login">Login</Link> : ''}
                      </NavDropDown>

                      <NavDropDown className='desktop-header-text' style={style} width={width} title="For Charities" icon="down">
                        <a href="https://www.neddieblog.com/neddie-for-charities">How It Works</a>
                        <a href="https://www.neddieblog.com/grant-central-faqs">Grant Central </a>
                        <a href="https://www.neddieblog.com/grant-central-plans-pricing">Plans & Pricing</a>
                        <a href="https://www.neddieblog.com/neddie-forum">Community Forum</a>
                        <a href="https://www.neddieblog.com/influencers-for-good">Influencers For Good</a>
                        <a href="https://www.neddieblog.com/faqs">FAQS</a>
                        <a href="https://www.neddieblog.com/">Resources</a>
                        {width > 800 && logged === 'loggedOut' ? <Link to="/login">Login</Link> : ''}
                      </NavDropDown>

                      <NavDropDown className='desktop-header-text' style={style} width={width} title="About" icon="down">
                        <a href="https://www.neddieblog.com/about">About Us</a>
                        {/* <a href="https://www.neddieblog.com/vetting-charities">How We Vet Charities</a> */}
                        <a href="https://www.neddieblog.com/testimonials">Testimonials</a>
                        <a href="https://www.neddieblog.com/">Blog</a>
                        <a href="https://www.neddieblog.com/faqs">FAQS</a>
                        <a href="https://www.neddieblog.com/contact">Contact Us</a>
                        {width > 800 && logged === 'loggedOut' ? <Link to="/login">Login</Link> : ''}
                      </NavDropDown>

                    </div>


                    : ''}
                </div>
              </div>
              :
              (width < 800 && logged === 'individual' && logged !== 'loggedOut' && logged !== 'nonprofit' &&
                <div className="col-lg-7 col-md-2 col-4 my-auto left-right">
                  <Link to="/">NEDDIE</Link> </div>)
            }


            {width > 800 && logged === 'nonprofit' ?
              <div className={`${path.split('/')[1] === 'campaigns' || path.split('/')[1] === 'search' ? 'col-lg-10' : 'col-lg-8'} col-md-2 col-3 my-auto left-right`}>
                <div className="row ">
                  {width > 800 ? path.split('/')[1] === 'campaigns' || path.split('/')[1] === 'search' ? ('') :
                    (<div className="col-lg-4 col-sm-12 p-0"><SearchBar /></div>) : <Link to="/">NEDDIE</Link>}

                  {width > 800 ?

                    <div className="col-lg-7 desktop-header desktop-headers my-auto p-0">
                      <NavDropDown className='desktop-header-text' style={style} width={width} title="For Donors" icon="down" >
                        <a href="https://www.neddieblog.com/neddie-for-donors"> How It Works</a>
                        {path.split('/')[1] === 'campaigns' || path.split('/')[1] === 'search' ?
                          <a>Fundraisers</a>
                          : <Link to={{ pathname: '/search', state: { searchCategory: 'Campaigns', }, }}>
                            Fundraisers
                          </Link>}
                        <a href="https://www.neddieblog.com/purpose-match">Purpose Match</a>
                        <a href="https://www.neddieblog.com/">Resources</a>
                        <a href="https://www.neddieblog.com/faqs">FAQ</a>
                        {width > 800 && logged === 'loggedOut' ? <Link to="/login">Login</Link> : ''}
                      </NavDropDown>

                      <NavDropDown className='desktop-header-text' style={style} width={width} title="For Charities" icon="down">
                        <a href="https://www.neddieblog.com/neddie-for-charities">How It Works</a>
                        <a href="https://www.neddieblog.com/grant-central-faqs">Grant Central </a>
                        <a href="https://www.neddieblog.com/grant-central-plans-pricing">Plans & Pricing</a>
                        <a href="https://www.neddieblog.com/neddie-forum">Community Forum</a>
                        <a href="https://www.neddieblog.com/influencers-for-good">Influencers For Good</a>
                        <a href="https://www.neddieblog.com/faqs">FAQS</a>
                        <a href="https://www.neddieblog.com/">Resources</a>
                        {width > 800 && logged === 'loggedOut' ? <Link to="/login">Login</Link> : ''}
                      </NavDropDown>

                      <NavDropDown className='desktop-header-text' style={style} width={width} title="About" icon="down">
                        <a href="https://www.neddieblog.com/about">About Us</a>
                        <a href="https://www.neddieblog.com/testimonials">Testimonials</a>
                        <a href="https://www.neddieblog.com/">Blog</a>
                        <a href="https://www.neddieblog.com/faqs">FAQS</a>
                        <a href="https://www.neddieblog.com/contact">Contact Us</a>
                        {width > 800 && logged === 'loggedOut' ? <Link to="/login">Login</Link> : ''}
                      </NavDropDown>

                    </div>
                    : <div className="col-lg-7 col-md-2 col-4 my-auto left-right">
                      <Link to="/">NEDDIE</Link> </div>}
                </div>
              </div> :
              (width < 800 && logged === 'nonprofit' && logged !== 'individual' && logged !== 'loggedOut' &&
                <div className="col-lg-7 col-md-2 col-4 my-auto left-right">
                  <Link to="/">NEDDIE</Link> </div>)
            }



            {width > 800 && logged === 'loggedOut' ?
              <div className="col-lg-3 m-0 p-0">

                <div class="text-right">
                  <Link to="/login" className='ml-4 login'>Login</Link>
                  <NeddieButton className='ml-4 signup' type="link" src={'/signup'} value={'Sign Up'}></NeddieButton>
                </div>
              </div>

              :

              <span
                id="navbar-right"
                className={style === 'trans' ? 'navbar-right-trans' : ''}
              >
                {burgerOrNot()}
              </span>
            }
          </div>
        </div>

        {path.split('/')[1] === 'campaigns' ||
          path.split('/')[1] === 'search' ? (
          ''
        ) : (
          <div className={`container ${showSearch ? 'hide-search-bar' : 'show-search-bar'}`}>
            <div className="row">
              <div className="col-12 mt-3">
                <SearchBar />
              </div>
            </div>
          </div>
        )}



      </nav>

      <DonorProfile
        show={showProfileModal}
        hide={() => setShowProfileModal(false)}
      />
      <DonorSettingModal
        show={showSettingModal}
        hide={() => setShowSettingModal(false)}
      />
    </>
  );
};

export default Navtab;