import React from 'react';
import ValidationText from '../../../components/Validator/validationText';

import { Col, Row } from 'react-bootstrap';

const ValidationInput = {
  newPassword: 'newPassword',
  currentPassword: 'currentPassword',
  confirmNewPassword: 'confirmNewPassword'
};

const PasswordValidationDisplay = (props: {
  MinCharacterLength?: boolean;
  Empty?: boolean;
  NotPasswordMatch?: boolean;
  currentPasswordNotMatch?: boolean;
  TextValidation_Input: {
    input: string;
    minLength?: number;
  };
  style?: any;
}) => {
  return (
    <div className='PasswordValidationBox'>
      <Row style={props.style}>
        {ValidationInput.newPassword === props.TextValidation_Input.input && (
          <>
            <Col>
              {props.MinCharacterLength === true && (
                <ValidationText
                  text={
                    'Password must be more than ' +
                    props.TextValidation_Input.minLength +
                    ' characters'
                  }
                  validationState={false}
                  invalidColor='red'
                />
              )}
              <br />
              {props.Empty === true && (
                <ValidationText
                  text='Password cannot be empty !'
                  validationState={false}
                  invalidColor='red'
                />
              )}
            </Col>
          </>
        )}
        {ValidationInput.confirmNewPassword ==
          props.TextValidation_Input.input && (
          <>
            {props.NotPasswordMatch === true && (
              <ValidationText
                text='Passwords do not match !'
                validationState={false}
                invalidColor='red'
              />
            )}
          </>
        )}
        {ValidationInput.currentPassword ==
          props.TextValidation_Input.input && (
          <>
            {props.currentPasswordNotMatch === true && (
              <ValidationText
                text='This does not match your current password!'
                validationState={false}
                invalidColor='red'
              />
            )}
          </>
        )}
        {/* {ValidationInput.email == props.TextValidation_Input.input && (
          <>
            <Col>
              {!props.ValidationState.NotEmpty && (
                <ValidationText
                  text='Email cannot be empty !'
                  validationState={false}
                  invalidColor='red'
                />
              )}
              {!props.ValidationState.Format && (
                <ValidationText
                  text='Email is invalid'
                  validationState={false}
                  invalidColor='red'
                />
              )}
            </Col>
          </>
        )} */}
      </Row>
    </div>
  );
};

export default PasswordValidationDisplay;
