// import React from 'react'

// function Acount() {
//   return (
//     <div>Acount</div>
//   )
// }

// export default Acount

import React, { useState } from 'react';
// import './settings.css';
// import ConfirmDeleteAccount from './ConfirmDeleteAccount';
// import ChangePassword from './ChangePassword';
// import EditImage from './EditImage';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { saveUser, saveOrg } from '../../../redux/actions';
import API from '../../../utils/API';
import defaultLogo from '../../../images/Profile_avatar.png';
// import EditImage from '../settings/EditImage';
import ConfirmDeleteAccount from '../settings/ConfirmDeleteAccount';
// import ChangePassword from '../settings/ChangePassword';

const Acount: React.FC = () => {
  const [mode, setMode] = useState<'default' | 'edit' | 'saving'>('default');
  const [isConfirmDeleteAccount, setConfirmDeleteAccount] = useState(false);
  // const [isChangePassword, setChangePassword] = useState(false);
  // const [isEditImage, setEditImage] = useState(false);
  const dispatch = useDispatch();
  const redux = useSelector((state: any) => state);
  const reduxOrg = redux?.org;
  const reduxUser = redux?.signin;
  // const [input, setInput]: any = useState({ npID: reduxUser?.npID });
  const [input, setInput]: any = useState({ npID: reduxOrg._id });

  const submit = async () => {
    setMode('saving');
    if (input.email || input.confirmEmail) {
      if (!isValidEmail()) {
        window.alert('Please enter a valid email address');
        setMode('edit');
        return;
      }
      if (input.email !== input.confirmEmail) {
        window.alert('Email address and confirmation must match');
        setMode('edit');
        return;
      }
    }
    const res: any = await API.updateDashboard(input).catch((error) =>{
      console.log(error);
          window.alert(
              'We encountered an error. Please contact us if it happens again, thanks!'
          )}
      );
    console.log(res);
    if (res?.data?.user) {
      dispatch(saveUser({ ...reduxUser, ...input }));
    }
    if (res?.data?.nonprofit?.name) {
      dispatch(saveOrg(res?.data?.nonprofit));
    }
    setInput({ npID: reduxOrg?._id });
    setMode('default');
  };

  const isValidEmail = () => {
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      input.email
    );
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { name, value } = e.target;
    if (value === '') {
      const temp = { ...input };
      delete temp[name];
      setInput({ ...temp });
      console.log('removed', name, 'from input obj');
      return;
    }
    setInput((input: any) => ({
      ...input,
      [name]: value
    }));
  };

  // toggle popup
  // const editImage = () => {
  //   setEditImage(true);
  // };
  // toggle popup
  // const changePassword = () => {
  //   setChangePassword(true);
  // };
  //cancel delete
  const cancelDelete = () => {
    setConfirmDeleteAccount(false);
  };
  //toggles confirm delete message
  const confirmDeleteAccount = () => {
    setConfirmDeleteAccount(true);
  };
  // const closePopup = () => {
  //   // setEditImage(false);
  //   setChangePassword(false);
  // };
  // const submitForms = () => {
  //   document.getElementById('user-info-form');
  //   document.getElementById('user-account-info');

  // };

  return (
    <div className='side-nav-settings-page-container'>
      <div className='side-nav-settings-page-left-col'>
        <div className='side-nav-settings-logo-container'>
          <h1 className='manage-marketplace-h2'>General Info</h1>
          <img
            className='side-nav-settings-image-logo'
            src={reduxOrg.images?.logo ? reduxOrg.images?.logo : defaultLogo}
          ></img>
          {/* {mode === 'edit' ? (
            <button
              className='side-nav-settings-logo-edit-btn'
              onClick={editImage}
            >
              Edit Image
            </button>
          ) : null} */}

          {/* {isEditImage ? <EditImage closePopup={closePopup} /> : null} */}
        </div>
        <div className='side-nav-settings-user-info'>
          {/* <h2>NONPROFIT INFORMATION</h2> */}
          <form
            className='side-nav-settings-user-info-form'
            id='user-info-form'
          >
            {mode === 'default' ? (
              <>
                <div className='side-nav-settings-info-div'>
                  <h5>Organization Name</h5>
                  <p>{reduxOrg.name}</p>
                </div>
                <div className='side-nav-settings-info-div'>
                  <h5>Phone</h5>
                  <p>{reduxOrg.telephone}</p>
                </div>
                <div className='side-nav-settings-info-div'>
                  <h5>Address</h5>
                  <p>{reduxOrg?.address?.addressLine1}, {reduxOrg?.address?.addressLine2},  {reduxOrg?.address?.city}, {reduxOrg?.address?.state}, {reduxOrg?.address?.zipcode}</p>
                  {/* <p>{reduxOrg.address.addressLine2}</p>
                  <p>{reduxOrg.address.city}</p>
                  <p>{reduxOrg.address.state}</p>
                  <p>{reduxOrg.address.zipcode}</p> */}
                </div>
              </>
            ) : (
              <>
                <p>Organization Name</p>
                <input
                  type='text'
                  name='organizationName'
                  defaultValue={reduxOrg.name}
                  onChange={handleChange}
                />
                <p>Phone</p>
                <input
                  type='text'
                  name='phone'
                  defaultValue={reduxOrg.telephone}
                  onChange={handleChange}
                />
                <p>
                  <label htmlFor='email'>Email</label>
                </p>
                <input
                  type='text'
                  name='email'
                  defaultValue={reduxUser.email}
                  onChange={handleChange}
                />
                <p>
                  <label htmlFor='confirmEmail'>Confirm Email</label>
                </p>
                <input
                  type='text'
                  name='confirmEmail'
                  defaultValue={reduxUser.email}
                  onChange={handleChange}
                />

              </>
            )}
          </form>
        </div>
      </div>
      <div className='side-nav-settings-page-right-col'>
        <div className='side-nav-settings-save-btn-container'>
          {mode === 'edit' ? (
            <div>
              <button type='submit' style={{ marginRight: '1rem' }} onClick={submit}>Save</button>
              <button type='submit' onClick={() => { setInput({ npID: reduxOrg._id }); setMode('default'); }}>Cancel</button>
            </div>
          ) : (
            <button onClick={() => setMode('edit')}>Edit</button>
          )}
        </div>
        <div className='side-nav-settings-account-info'>
          {/* <h2>USER INFORMATION</h2> */}
          <form
            action=''
            className='side-nav-settings-account-info-form'
            id='account-info-form'
          >
            {mode === 'default' ? (
              <>
                <div className='side-nav-settings-account-info-form-top'>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginRight: '3vw',
                      marginBottom: '2vw'
                    }}
                  >
                    <h5>First Name</h5>
                    <p>{reduxUser.firstName}</p>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <h5>Last Name</h5>
                    <p>{reduxUser.lastName}</p>
                  </div>
                </div>
                <div className='side-nav-settings-account-info-form-bottom'>
                  <h5>Email</h5>
                  <p>{reduxUser.email}</p>
                </div>
              </>
            ) : (
              <>
                {/* <div className='side-nav-settings-account-info-form-top'>

                  <div style={{ display: 'flex', flexDirection: 'column', marginRight: '3vw' }}>
                    <p><label htmlFor='firstName'>FIRST NAME</label></p>
                    <input
                      type='text'
                      name='firstName'
                      placeholder={reduxUser.firstName}
                      onChange={handleChange}
                    />
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <p>
                      <label htmlFor='lastName'>LAST NAME</label>
                    </p>
                    <input
                      type='text'
                      name='lastName'
                      placeholder={reduxUser.lastName}
                      onChange={handleChange}
                    />
                  </div>
                </div> */}
                <div className='side-nav-settings-account-info-form-bottom'>
                  <p>
                    <label htmlFor='confirmEmail'>Address</label>
                  </p>
                  <input
                    type='text'
                    name='address'
                    defaultValue={reduxOrg.address.addressLine1}
                    onChange={handleChange}
                  />
                  <p>
                    <label htmlFor='confirmEmail'>Address 2</label>
                  </p>
                  <input
                    type='text'
                    name='address2'
                    defaultValue={reduxOrg.address.addressLine2}
                    onChange={handleChange}
                  />
                  <div style={{display:'flex'}}>
                  <div style={{ display: 'flex', flexDirection: 'column', marginRight: '3vw' }}>
                    <p>City</p>
                    <input
                      type='text'
                      name='city'
                      defaultValue={reduxOrg.address.city}
                      onChange={handleChange}
                    />
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column'}}>
                  <p>State</p>
                  <input
                    type='text'
                    name='state'
                    defaultValue={reduxOrg.address.state}
                    onChange={handleChange}
                  />
                </div>
                </div>
                <p>Zip Code</p>
                <input
                  type='text'
                  name='zipcode'
                  defaultValue={reduxOrg.address.zipcode}
                  onChange={handleChange}
                />
              </div>
          </>
            )}
        </form>
      </div>
      {mode === 'edit' ? (
        <button
          className='side-nav-settings-delete-account-btn '
          onClick={confirmDeleteAccount}
        >
          Delete Account
        </button>
      ) : null}

      {isConfirmDeleteAccount ? (
        <ConfirmDeleteAccount cancelDelete={cancelDelete} />
      ) : null}
    </div>
    </div >
  );
};

export default Acount;
