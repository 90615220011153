import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { ValidationText } from '../donorSettingModal/donorSettingValidation';
import validations from '../../../components/Validator/validationRules';

interface IPhoneValidationInputs {
  MinCharacterLength: boolean | undefined;
  MaxCharacterLength: boolean | undefined;
  isNumber: boolean | undefined;
  NotEmpty: boolean | undefined;
}

const TelephoneInput = (props: {
  name: string;
  placeholder: string;
  handleChange: any;
  value: string;
  setUserValidation: any;
}) => {
  const [phoneValidations, setPhoneValidation] =
    useState<IPhoneValidationInputs>({
      MinCharacterLength: undefined,
      MaxCharacterLength: undefined,
      isNumber: undefined,
      NotEmpty: undefined
    });

  const validateInput = (e: any) => {
    const { name, value } = e.target;
    let newValidationState = phoneValidations;

    if (name === props.name) {
      newValidationState.MaxCharacterLength = validations.maxStringLength(
        value,
        13
      );

      //if the minlength is false, BUT the value is empty, make the minLength true.Because we accept empty value as user input
      if (validations.minStringLength(value, 10) === false) {
        if (validations.NotEmpty(value) === false) {
          newValidationState.MinCharacterLength = true;
        } else {
          newValidationState.MinCharacterLength = false;
        }
      } else {
        newValidationState.MinCharacterLength = true;
      }

      newValidationState.isNumber = validations.isNumber(value);
      newValidationState.NotEmpty = validations.NotEmpty(value);
    }
    setPhoneValidation({ ...newValidationState });
    props.handleChange(value);
  };

  useEffect(() => {
    if (
      phoneValidations.MaxCharacterLength === false ||
      phoneValidations.MinCharacterLength === false ||
      phoneValidations.isNumber === false
    ) {
      props.setUserValidation(false);
    } else {
      props.setUserValidation(true);
    }
  }, [phoneValidations]);

  return (
    <>
      <Form.Control
        type='text'
        placeholder={props.placeholder}
        name={props.name}
        value={props.value}
        onChange={validateInput}
        className='donorSetting-preferenceInput'
      />
      <div className='donorSettings-Validations ml-2'>
        <ValidationText
          validationState={phoneValidations.MaxCharacterLength}
          text='Phone number is invalid'
        />
        <ValidationText
          validationState={phoneValidations.MinCharacterLength}
          text='Phone number too short'
        />
        <ValidationText
          validationState={phoneValidations.isNumber}
          text='Phone number should not contains letters or space'
        />
      </div>
    </>
  );
};

export default TelephoneInput;
