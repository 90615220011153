import React from 'react'
import { Modal } from 'react-bootstrap';
import fifteen from '../../../images/daskboard/15.png'
import './dashboardSetting.css'
import { FaMicroblog, FaRegUserCircle, FaUsers } from 'react-icons/fa';
import { IoIosChatbubbles } from 'react-icons/io';

interface ISharingOption {
  daskboardMorePopup?: boolean; //to make the share symbol into a Neddie Button
  setDaskboardMorePopup?: (value: boolean) => void; //to make the share button light
}

const DashboardMorePopup: React.FC<ISharingOption> = ({ setDaskboardMorePopup, daskboardMorePopup }) => {
  const handleSetWishList = () => {
    if (setDaskboardMorePopup) {
      setDaskboardMorePopup(!daskboardMorePopup);
    }
  };

  return (
    <Modal centered show={daskboardMorePopup} onHide={() =>{ handleSetWishList()}} className='mobile-setting-popups'>
      <div id='hide-on-mobile'>
        <div className='manage-wish-wrap' >
          <span className='cancle-popup' onClick={() =>{ handleSetWishList()}}>x</span>
          <div className="left">
            <h1 className='manage-marketplace-h2'>More <img src={fifteen} style={{ width: '22px', marginTop: '-0px' }} alt="" /></h1>
            <div className="tab-listing">
              <ul>
              <li><a href="https://www.neddieblog.com/neddie-for-donors" target='_blank'><span><FaUsers /></span>For Donors</a> </li>
              <li><a href="https://www.neddieblog.com/about" target='_blank'><span><FaRegUserCircle /></span>About Us</a></li>
              <li> <a href="https://www.neddieblog.com/" target='_blank'><FaMicroblog style={{color:'#94989b', marginRight:'5px'}}/>Blog</a></li>
              <li> <a href="https://www.neddieblog.com/faqs" target='_blank'><span><IoIosChatbubbles /></span>FAQ’s</a></li>
              </ul>
            </div>
          </div>
          <div className="center"></div>
        </div>
      </div>
    </Modal>
  )
}

export default DashboardMorePopup