import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, Image, InputGroup } from 'react-bootstrap';
import './donorSetting.css';
import VerifiedCheck from '../../../images/Icon/verifiedCheck.png';
// import LearnButton from '../../Button';
import Modal from 'react-bootstrap/Modal';
import { saveUser } from '../../../redux/actions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import API from '../../../utils/API';
import validations from '../../Validator/validationRules';
import {
  IValidationInputs,
  ValidateOverallForm,
  ValidationText
} from './donorSettingValidation';
import useDebounce from '../../../utils/debounce';

const DonorSettingModal = (props: { show: boolean; hide: () => void }) => {
  const [validationInputs, setValidationInputs] = useState<IValidationInputs>({
    overall_ValidationSuccess: undefined,
    Validation: {
      Password: {
        MinCharacterLength: undefined,
        MaxCharacterLength: undefined,
        NotEmpty: undefined,
        NumberIncluded: undefined
      },
      Email: {
        Format: undefined,
        NotEmpty: undefined
      },
      PhoneNumber: {
        MinCharacterLength: undefined,
        MaxCharacterLength: undefined,
        isNumber: undefined,
        NotEmpty: undefined
      }
    }
  });
  const [updatedSuccess, setUpdatedSuccess] = useState<boolean>(false);
  const [confirmPasswordSuccess, setConfirmPasswordSuccess] =
    useState<boolean>(false);
  const [showChangePasswordInput, setShowChangePasswordInput] =
    useState<boolean>(false);
  const dispatch = useDispatch();
  const reduxUser = useSelector((state: any) => state.signin);
  const [formState, setFormState] = useState({
    password: '',
    confirmPassword: '',
    email: reduxUser.email,
    telephone: '3602303',
    _id: reduxUser._id
  });

  let debouncedSearchTerm: any;
  debouncedSearchTerm = useDebounce(formState.confirmPassword, 300);

  useEffect(() => {
    // //fetch User telephone
    // API.getUserPhoneNumber(reduxUser._id).then((user:any)=>{
    //   // console.log("the user from api",user)
    //   setFormState({...formState,telephone:user.data.telephone})
    // }).catch((error:any)=>{
    //   console.log(error.message)
    //   console.log("error")
    // })
  }, []);

  useEffect(() => {
    if (debouncedSearchTerm.length !== 0) {
      //make API call to confirm password
      console.log('confirming PASSWORD !!!!!!');
      API.checkPassword(reduxUser._id, debouncedSearchTerm)
        .then((response: any) => {
          console.log(response.data.passwordMatch);
          setConfirmPasswordSuccess(response.data.passwordMatch);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
    }
  }, [debouncedSearchTerm]);

  const populatingDonorForm = async () => {
    let newFormState = {
      password: '',
      confirmPassword: '',
      email: reduxUser.email,
      telephone: 'unable to get your phone number',
      _id: reduxUser._id
    };
    //fetch User telephone
    await API.getUserPhoneNumber(reduxUser._id)
      .then((user: any) => {
        // console.log("the user from api",user)
        newFormState = { ...newFormState, telephone: user.data.telephone };
      })
      .catch((error: any) => {
        console.log(error.message);
        console.log('error');
      });

    setFormState(newFormState);
  };
  const resetValidationsOnExit = () => {
    //reset validations
    setValidationInputs({
      overall_ValidationSuccess: undefined,
      Validation: {
        Password: {
          MinCharacterLength: undefined,
          MaxCharacterLength: undefined,
          NotEmpty: undefined,
          NumberIncluded: undefined
        },
        Email: {
          Format: undefined,
          NotEmpty: undefined
        },
        PhoneNumber: {
          MinCharacterLength: undefined,
          MaxCharacterLength: undefined,
          isNumber: undefined,
          NotEmpty: undefined
        }
      }
    });
  };

  const validatingDonorSettings = (inputName: string, inputValue: string) => {
    let newValidationState = validationInputs;
    newValidationState.overall_ValidationSuccess = undefined;
    if (inputName === 'email') {
      newValidationState.Validation.Email.Format =
        validations.isEmail(inputValue);
      newValidationState.Validation.Email.NotEmpty =
        validations.NotEmpty(inputValue);
    } else if (inputName === 'password') {
      newValidationState.Validation.Password.MaxCharacterLength =
        validations.maxStringLength(inputValue, 40);
      newValidationState.Validation.Password.MinCharacterLength =
        validations.minStringLength(inputValue, 8);
      newValidationState.Validation.Password.NumberIncluded =
        validations.hasNumber(inputValue);
      newValidationState.Validation.Password.NotEmpty =
        validations.NotEmpty(inputValue);
    } else if (inputName === 'telephone') {
      newValidationState.Validation.PhoneNumber.MaxCharacterLength =
        validations.maxStringLength(inputValue, 13);
      newValidationState.Validation.PhoneNumber.MinCharacterLength =
        validations.minStringLength(inputValue, 10);
      newValidationState.Validation.PhoneNumber.isNumber =
        validations.isNumber(inputValue);
      newValidationState.Validation.PhoneNumber.NotEmpty =
        validations.NotEmpty(inputValue);
    }
    setValidationInputs({ ...newValidationState });
  };

  const handleChange = (e: any) => {
    //change updateSuccess to false if the user is changing the inputs in the form
    updatedSuccess === true && setUpdatedSuccess(false);

    setFormState({
      ...formState,
      [e.target.name]: e.target.value
    });

    //validating input settings
    e.target.name !== 'confirmPassword' &&
      validatingDonorSettings(e.target.name, e.target.value);
  };

  const ChangeUserPassword = () => {
    setShowChangePasswordInput(!showChangePasswordInput);
  };

  const SaveUserSetting = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    console.log(formState);

    //validate the overall form then proceed if successfull
    if (ValidateOverallForm(validationInputs) === false) {
      setValidationInputs({
        ...validationInputs,
        overall_ValidationSuccess: false
      });
      return;
    }

    //Update User
    const res: any = await API.updateUser(formState).catch((err: any) =>
      console.log(err)
    );

    if (res.status === 200) {
      setUpdatedSuccess(true);
      dispatch(saveUser({ ...reduxUser, email: formState.email }));
    }
  };

  // const deleteUser = () => {};

  return (
    <Modal
      show={props.show}
      onHide={props.hide}
      onEnter={populatingDonorForm}
      onExit={resetValidationsOnExit}
      className='navbar-UserSetting-Modal'
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1 className='donorSetting-modal-title'>Settings</h1>
          {updatedSuccess === true && (
            <div className='donorSetting-updatedGreenCheck'>
              <Image src={VerifiedCheck} />
            </div>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='donorSetting-modal'>
        <Form className='donorSetting-modal-form'>
          <Form.Group>
            <Row>
              <Col>
                {' '}
                <Form.Label>PASSWORD</Form.Label>
              </Col>
              <Col className='text-right'>
                <small onClick={ChangeUserPassword} className='text-primary'>
                  <u>Change Password</u>
                </small>
              </Col>
            </Row>
            <Form.Control
              value='*********'
              name='password'
              type='password'
              placeholder='Password'
              disabled
            />
            {showChangePasswordInput === true && (
              <>
                <Form.Label className='mt-4'>CONFIRM PASSWORD</Form.Label>
                <InputGroup>
                  <Form.Control
                    value={formState.confirmPassword}
                    name='confirmPassword'
                    type='password'
                    placeholder='Confirm Password'
                    className='donorSetting-modal-input'
                    onChange={(e: any) => handleChange(e)}
                    style={{
                      borderRight: confirmPasswordSuccess === true ? 'none' : ''
                    }}
                  />
                  {confirmPasswordSuccess === true && (
                    <InputGroup.Append>
                      <InputGroup.Text className='donorSetting-confirmPasswordCheck'>
                        <Image src={VerifiedCheck} style={{ width: '2rem' }} />
                      </InputGroup.Text>
                    </InputGroup.Append>
                  )}
                </InputGroup>
                {confirmPasswordSuccess === true && (
                  <div className='mt-3'>
                    <Form.Label className='font-weight-bold'>
                      NEW PASSWORD
                    </Form.Label>
                    <Form.Control
                      value={formState.password}
                      name='password'
                      type='password'
                      placeholder='New password'
                      className='donorSetting-modal-input'
                      onChange={(e: any) => handleChange(e)}
                    />
                    <small className='mt-3 ml-2 d-block'>
                      Enter a strong password
                    </small>
                    <div className='donorSettings-Validations'>
                      <ValidationText
                        validationState={
                          validationInputs?.Validation.Password
                            .MaxCharacterLength
                        }
                        text='Password too long'
                      />
                      <ValidationText
                        validationState={
                          validationInputs?.Validation.Password
                            .MinCharacterLength
                        }
                        text='Password too short'
                      />
                      <ValidationText
                        validationState={
                          validationInputs?.Validation.Password.NumberIncluded
                        }
                        text='Password does not contain a number'
                      />
                      <ValidationText
                        validationState={
                          validationInputs?.Validation.Password.NotEmpty
                        }
                        text='Password cannot be empty'
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>EMAIL</Form.Label>
            <Form.Control
              value={formState.email}
              name='email'
              type='text'
              placeholder='Email'
              className='donorSetting-modal-input'
              onChange={(e: any) => handleChange(e)}
            />
            <div className='donorSettings-Validations'>
              <ValidationText
                validationState={validationInputs?.Validation.Email.Format}
                text='Invalid Format'
              />
              <ValidationText
                validationState={validationInputs?.Validation.Email.NotEmpty}
                text='Email cannot be empty'
              />
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label>PHONE NUMBER</Form.Label>
            <Form.Control
              value={formState.telephone}
              name='telephone'
              type='text'
              placeholder='Phone Number'
              className='donorSetting-modal-input'
              onChange={(e: any) => handleChange(e)}
            />
            <div className='donorSettings-Validations'>
              <ValidationText
                validationState={
                  validationInputs?.Validation.PhoneNumber.MaxCharacterLength
                }
                text='Phone number is invalid'
              />
              <ValidationText
                validationState={
                  validationInputs?.Validation.PhoneNumber.MinCharacterLength
                }
                text='Phone number too short'
              />
              <ValidationText
                validationState={
                  validationInputs?.Validation.PhoneNumber.isNumber
                }
                text='Phone number should not contains letters'
              />
              <ValidationText
                validationState={
                  validationInputs?.Validation.PhoneNumber.NotEmpty
                }
                text='Phone number cannot be empty'
              />
            </div>
          </Form.Group>
          <Row className='Donor-setting-action-Buttons'>
            <Col>
              <Button
                variant='primary'
                type='submit'
                className='donorSetting-modal-save backgroundColor-ocean '
                onClick={(e: any) => SaveUserSetting(e)}
              >
                Save
              </Button>
            </Col>

            <Col className='text-right'>
              {/* <small onClick={deleteUser} className='text-secondary'>
                <u>Delete Account</u>
              </small> */}
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <div className='donorSetting-overall-validation-message modal-footer border-0'>
        <ValidationText
          validationState={validationInputs?.overall_ValidationSuccess}
          text='Invalid Entry'
        />
      </div>
    </Modal>
  );
};

export default DonorSettingModal;
