import React from 'react';
import './style.css';

import WWOCard from '../../components/whatWeOffer/wwoCard';

import ww0Card1 from '../../images/MaskGroup19.svg';
import ww0Card2 from '../../images/Mask Group 20.svg';
import ww0Card3 from '../../images/Mask Group 21.svg';
import ww0Card4 from '../../images/Mask Group 22.svg';
import ww0Card5 from '../../images/Mask Group 23.svg';
import ww0Card6 from '../../images/Mask Group 24.svg';

import topCut from '../../images/top-cut.svg';

import laptop1 from '../../images/nonprofit.png';
import phones from '../../images/mobile-screens.png';

import Footer from '../../components/footer/topfooter';
import BottomFooter from '../../components/footer/bottomfooter';

const About: React.FC = () => {
  return (
    <div>
      {/* section 2 (what we offer cards) */}
      <div className='section-two'>
        <div className='what-we-offer'>NEDDIE Can Help!</div>
        <div className='what-we-offer-row'>
          <WWOCard
            img={ww0Card3}
            title='Accelerate Collaboration'
            desc='Maximize your social impact with our end-to-end social media platform. We deliver visibility into philanthropic activities that multiply exponentially and inspire others to get involved and take action.'
          ></WWOCard>
          <WWOCard
            img={ww0Card4}
            title='Build Exposure'
            desc='Our innovative search engine and marketing tools provide donors quick and easy access to thousands of nonprofits, while allowing nonprofits to gain viable new donors.'
          ></WWOCard>
          <WWOCard
            img={ww0Card5}
            title='Provide Transparency'
            desc='We deliver unmatched transparency by promoting organizations that are creating a social impact. We empower these organizations to establish credibility and earn donors’ trust.'
          ></WWOCard>
        </div>
        <div className='what-we-offer-row'>
          <WWOCard
            img={ww0Card2}
            title='Harness Data'
            desc='Our  data and analysis tools help track donations, growth and reach. You can access reports to make better informed decisions for your organization.'
          ></WWOCard>
          <WWOCard
            img={ww0Card1}
            title='Solidify B2B Connections'
            desc='Multiply your connections by sharing and finding opportunities that align on shared values and initiatives. Now you can access the right contacts to start meaningful discussions and propel change.'
          ></WWOCard>
          <WWOCard
            img={ww0Card6}
            title='Fuel Growth'
            desc={`Our budgeting tools help nonprofits keep their expenses down and their revenue up. We help businesses find new customers within their own products and service's catalog.`}
          ></WWOCard>
        </div>
      </div>

      {/* beginning of section 3 (nonprofit callout) */}
      <img className='top-cut-svg' alt='oops' src={topCut}></img>
      <div id='nonprofits' className='section-three'>
        <div className='nonprofit-callout-desktop-wrapper'>
          <div className='nonprofit-callout'>
            <div className='nonprofit-callout-title'>Nonprofits</div>
            <div className='nonprofit-callout-desc'>
              Exceed your business needs with an automated functional dashboard
              that includes marketing, B2B collaboration, budgeting tools,
              campaigns, donation analytics, and much more.
            </div>
            <a href='/signup' className='get-started-now'>
              Sign Up Now
            </a>
          </div>
          <div className='nonprofit-callout-img'>
            <img className='laptop1' alt='oops' src={laptop1}></img>
          </div>
        </div>

        <div className='nonprofit-callout-mobile-wrapper'>
          <div className='nonprofit-callout'>
            <div className='nonprofit-callout-title'>Nonprofits</div>
          </div>
          <div className='nonprofit-callout-img'>
            <img className='laptop1' alt='oops' src={laptop1}></img>
          </div>
          <div className='nonprofit-callout-desc'>
            Exceed your business needs with an automated functional dashboard
            that includes marketing, B2B collaboration, budgeting tools,
            campaigns, donation analytics, and much more.
          </div>
          <a href='/signup' className='get-started-now'>
            Sign Up Now
          </a>
        </div>
      </div>

      {/* beginning of section 4 (Donor callout) */}
      <div id='donors' className='section-four'>
        <div className='donor-callout-desktop-wrapper'>
          <div className='donor-callout-img'>
            <img className='phones' alt='oops' src={phones}></img>
          </div>
          <div className='donor-callout'>
            <div className='donor-callout-title'>Donors</div>
            <div className='donor-callout-desc'>
              Be empowered to choose how you engage with nonprofits, share your
              social impact stories, track your donations and volunteer hours,
              and get rewarded!
            </div>
            <a href='https://neddie.co/signup' className='get-started-now'>
              Sign Up Now
            </a>
          </div>
        </div>

        <div className='donor-callout-mobile-wrapper'>
          <div className='donor-callout'>
            <div className='donor-callout-title'>Donors</div>
          </div>
          <div className='donor-callout-img'>
            <img className='phones' alt='oops' src={phones}></img>
          </div>
          <div className='donor-callout-desc'>
            Be empowered to choose how you engage with nonprofits, share your
            social impact stories, track your donations and volunteer hours, and
            get rewarded!
          </div>
          <a href='https://neddie.co/signup' className='get-started-now'>
            Sign Up Now
          </a>
        </div>
      </div>
      <Footer />
      <BottomFooter />
    </div>
  );
};

export default About;
