import S3 from 'aws-s3';

const config = {
  bucketName: 'neddie-platform-images',
  dirName: 'photos' /* optional */,
  region: 'us-west-2',
  accessKeyId: 'AKIASPUIEEOO4CUXO34L',
  secretAccessKey: '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'
};

const S3Client = new S3(config);

export const deleteImage = async (profileImg: string) => {
  if (profileImg) {
    let splitUrl = profileImg.split('.');
    await S3Client.deleteFile(
      splitUrl[3].split('/')[2] + '.' + splitUrl[4]
    ).catch((e: any) => {
      console.log(e);
    });
  }
};

export const uploadImage = async (
  blob: any,
  fileName: string,
  oldImg: string
): Promise<string | boolean> => {
  try {
    const AWS_Image = await S3Client.uploadFile(blob, fileName);
    if (AWS_Image.location) {
      if (oldImg) {
        deleteImage(oldImg);
      }
      return AWS_Image.location;
    } else {
      return false;
    }
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const getFileKeyFromURL = (fileURL: string) => {

  if(!fileURL) {
    return "";
  }

  const splitUrl = fileURL.split('.');
  return splitUrl[3].split('/')[2] + '.' + splitUrl[4];
};

export const getOriginalFileURLFromCroppedURL = (croppedURL: string) => {
  if(!croppedURL) {
    return "";
  }
  
  const splitUrl = croppedURL.split('.');
  const keyPart = splitUrl[3].split('/');

  // Could have used join but did not want to use a specific string. Used the logic already in the code.
  return `${splitUrl[0]}.${splitUrl[1]}.${splitUrl[2]}.${keyPart[0]}/${keyPart[1]}/original_${keyPart[2]}.${splitUrl[4]}`;
};