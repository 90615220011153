import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// import ReactTooltip from 'react-tooltip';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Image, Modal } from 'react-bootstrap';
import SharingOptions from '../../../components/sharingOptions/sharingOptions';
// import { ProgressBar } from 'react-bootstrap';
import './quickSharePopup.css';
import profile from '../../../images/daskboard/profile.png'
import three from '../../../images/daskboard/3.png'
import four from '../../../images/daskboard/4.png'
import nine from '../../../images/daskboard/9.png'
import six from '../../../images/daskboard/6.png'
import API from '../../../utils/API';

interface ISharingOption {
  shareLink?: boolean; //to make the share symbol into a Neddie Button
  setShareLink?: (value: boolean) => void; //to make the share button light
}

const QuickSharePopup: React.FC<ISharingOption> = ({ shareLink, setShareLink }) => {
  const reduxOrg = useSelector((state: any) => state.org);
  const [nonProfit, setNonProfit] = useState([]);
  
  const handleSetWishList = () => {
    if (setShareLink) {
      setShareLink(!shareLink);
    }
  };

  useEffect(() => {
    API.getNpCampaigns(reduxOrg._id).then((result) => {
      console.log(result.data, 'non-profit');
      console.log(nonProfit, 'non-profit');
      setNonProfit(
        result.data.sort((a: any, b: any) => b.createdAt - a.createdAt)
      );
    });
    // console.log(campaigns, 'campaigns');
  }, []);

  return (
    <Modal centered show={shareLink} onHide={setShareLink} className='manage-share-popups'>
      <div className='manage-wish-wrap'>
        <span className='cancle-popup' onClick={handleSetWishList}>x</span>

        <div className='dashBoard-infocard-top-row'>
          <h2 className='dashboard-info-h2'>QUICK SHARE <img src={six} style={{ width: '22px', marginTop: '-5px' }} alt="" /></h2>
        </div>
        With Others
        <div className='dashboard-quick-link-box-container'>
          <div className="row" style={{width:'100%'}}>
            <div className="col-lg-3 col-6">
              <div className='dashboard-quick-link-box one'>
                <span className='dashboard-link-box'>
                  <Image className='dashboard-link-icon' src={profile} />
                </span>
                <div className='dashboard-link-box-text'>
                  <span>Profile</span>
                </div>

                <SharingOptions
                  componentHeaderText={'Share your profile page'}
                  url={'https://neddie.co/nonprofit/' + reduxOrg._id}
                  postDescription={
                    'Check out the ' + reduxOrg.name + ' profile page on Neddie'
                  }
                  //facebookHashtag='#Neddie'
                  twitterHashtags={['Neddie', 'Nonprofits']}
                />
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className='dashboard-quick-link-box two'>
                <span className='dashboard-link-box'>
                  <Image className='dashboard-link-icon ' src={four} />
                </span>
                <div className='dashboard-link-box-text'>
                  <span>Wishlist</span>
                </div>
                <SharingOptions
                  componentHeaderText={'Share your wishlist'}
                  url={reduxOrg.actionItems.wishlist}
                  postDescription={
                    'Check out the ' + reduxOrg.name + ' Wishlist '
                  }
                  //facebookHastag='#Neddie'
                  twitterHashtags={['Neddie', 'Nonprofits']}
                />
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className='dashboard-quick-link-box three'>
                <span className='dashboard-link-box'>
                  <Image className='dashboard-link-icon' src={three} />
                </span>
                <div className='dashboard-link-box-text'>
                  <span>Marketplace</span>
                </div>
                <SharingOptions
                  componentHeaderText={'Share your marketplace'}
                  url={reduxOrg.actionItems.marketplace}
                  postDescription={
                    'Check out the ' + reduxOrg.name + ' Marketplace '
                  }
                  //facebookHastag='#Neddie'
                  twitterHashtags={['Neddie', 'Nonprofits']}
                />
              </div>
            </div>
          {nonProfit.map((item: any, index: any) => {
            return (
              <div className="col-lg-3 col-6">
              <div className='dashboard-quick-link-box four' key={index}>
                <span className='dashboard-link-box'>
                  <Image className='dashboard-link-icon' src={nine} />
                </span>
                <div className='dashboard-link-box-text'>
                  <span>{item?.name}</span>
                </div>
                <SharingOptions
                  // componentHeaderText={'Share your donation page'}
                  componentHeaderText={'Help ' + item.name + ' by sharing this fundraiser'}
                  // url={'https://neddie.co/nonprofit/' + item?.id}
                  url={'https://www.neddie.co/donation/' + reduxOrg?._id + '/' + item?.id}
                  
                  // postDescription={
                  //   'Check out the ' + reduxOrg.name + ' Donation Page '
                  // }
                  postDescription={
                    'Help ' + item?.id + ' by sharing or donating to this fundraiser!'
                  }
                  // postDescription={
                  //   'Help ' + reduxOrg.name + ' by sharing or donating to this fundraiser!'
                  // }
                  //facebookHastag='#Neddie'
                  twitterHashtags={['NEDDIE', 'nonprofits', 'fundraiser']}
                />
              </div>
              </div>
            )
          })}
          </div>

          {/* <div className='dashboard-quick-link-box five'>
            
            <span className='dashboard-link-box'>
              <Image className='dashboard-link-icon' src={nine} />
            </span>
            <div className='dashboard-link-box-text'>
              <span>Fundraiser: Giving</span>
            </div>
            <SharingOptions
              componentHeaderText={'Share your donation page'}
              url={'https://neddie.co/donation/' + reduxOrg._id}
              postDescription={
                'Check out the ' + reduxOrg.name + ' Donation Page '
              }
              //facebookHastag='#Neddie'
              twitterHashtags={['Neddie', 'Nonprofits']}
            />
          </div> */}

        </div>
      </div>

    </Modal>
  );
};

export default QuickSharePopup;
