import React, { useState, useEffect, Suspense, lazy } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  RouteProps,
  Redirect
} from 'react-router-dom';
import GiveLocation from './components/giveLocation/GiveLocation';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
// import MobileInstallPopup from './pages/LandingPage/mobileInstallPopup/mobileInstallPopup';
// import Failure from './pages/DashboardPage/managePay/failurePage';
import ScrollToTop from './utils/scrollToTop';
// import MobileNavbar from '../src/components/mobileNav/mobileNavbar/mobileNavbar';
import Navtab from '../src/components/navbar';
import Fallback from './components/loadingSpinner/loadingSpinner';
import { incrementInstallPopup } from './redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import aboutPage from './pages/aboutPage';
import Dashboard from './pages/DashboardPage/dashboard';
// import ManagepayPage from './pages/managepayPage/ManagepayPage';
//import NpEventsPage from './pages/NpEventsPage/NpEventsPage';
const ManagepayPage = lazy(() => import('./pages/managepayPage/ManagepayPage'));
const NpEventsPage = lazy(() => import('./pages/NpEventsPage/NpEventsPage'));
const Home = lazy(() => import('./pages/LandingPage'));
const NPProfile = lazy(
  () => import('./pages/nonprofitProfile/nonprofitProfile')
);

const DonorProfile = lazy(() => import('./pages/DonorProfile/donorProfile'));
// const aboutPage = lazy(() => import('./pages/aboutPage'));
const forgot_PW_page = lazy(() => import('./pages/LoginPage/forgot_PW_page'));
const PwResetPage = lazy(() => import('./pages/LoginPage/PwResetPage'));
const LoginPage = lazy(() => import('./pages/LoginPage/LoginPage'));
const Admin = lazy(() => import('./pages/adminPage/admin'));
// const Dashboard = lazy(() => import('./pages/DashboardPage/dashboard'));
const thankyouPage = lazy(() => import('./pages/donationPage/thankyouPage'));
const DonorReport = lazy(
  () => import('./pages/mobileDonorTracking/mobileDonorTracking')
);
const Find = lazy(() => import('./pages/findOrganization'));
const NoMatch = lazy(() => import('./pages/NoMatch'));
const ThankYou = lazy(() => import('./pages/signUpPage/thankYou'));
const Approve = lazy(() => import('./pages/approvePage/approve'));
const DonationPage = lazy(() => import('./pages/donationPage/donationPage'));
const Subscribe = lazy(() => import('./pages/subscribePage/subscribePage'));
const Confirm = lazy(() => import('./pages/confirmPage/confirm'));
const Search = lazy(() => import('./pages/searchPage/search'));
const StripeFailure = lazy(
  () => import('./pages/DashboardPage/managePay/failurePage')
);
const EventPage = lazy(() => import('./pages/EventPage/eventPage'));
const EventTicketOrderPage = lazy(
  () => import('./pages/EventPage/TicketOrderPage/eventTicketOrder')
);
const CompleteDonation = lazy(() => import('./pages/completeDonation/CompleteDonation'));

const App = () => {
  type ProtectedRouteProps = {
    isAuthenticated: boolean;
    isAllowed: boolean;
    restrictedPath: string;
    authenticationPath: string;
  };
  const ProtectedRoute: React.FC<ProtectedRouteProps & RouteProps> = ({
    isAuthenticated,
    isAllowed,
    restrictedPath,
    authenticationPath,
    ...props
  }) => {
    let redirectPath = '';

    if (!isAuthenticated) {
      redirectPath = authenticationPath;
    }
    if (isAuthenticated && !isAllowed) {
      redirectPath = restrictedPath;
    }
    if (redirectPath) {
      const renderComponent = () => (
        <Redirect to={{ pathname: redirectPath }} />
      );
      return (
        <Route {...props} component={renderComponent} render={undefined} />
      );
    } else {
      return <Route {...props} />;
    }
  };

  const [email, setEmail] = useState('');
  // const redux = useSelector((state: any) => state);
  // const logged = useSelector((state: any) => state.signin.type);
  const dispatch = useDispatch();
  const reduxInstallPrompt = useSelector(
    (state: any) => state.displayInstallPopup
  );

  const dashAuth = (props: any) => {
    // if (!redux.org?._id ) {
    // if (logged ==='nonprofit' ) {
      return <Dashboard {...props} />;
    // }
    // if (logged === 'individual') {
    //   return <Dashboard {...props} />;
    // } else{
    //   return <Redirect to='/' />;
      
    // }
  };

  // const [mobileInstallMessage, setMobileInstallMessage] = useState(false);

  useEffect(() => {
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    };

    // Detects if device is in standalone mode
    // const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);
    const newNavigator: any = window.navigator;
    const isInStandaloneMode = () => newNavigator.standalone;

    // Checks if should display install popup notification:
    if (isIos() && !isInStandaloneMode() && !(reduxInstallPrompt % 5)) {
      // ******UNCOMMENT BELOW TO RE-ENABLE IOS PWA PROMPT*******
      // setMobileInstallMessage(true);
    }
  }, []);

  useEffect(() => {
    dispatch(incrementInstallPopup());
  }, []);

  // used to disable center column layout on dashboard
  const [path, setPath] = useState(window.location.pathname);

  const enableCenterLayout = (path: string) => {
    if (path.startsWith('/dashboard') || path.startsWith('/admin')) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div>
      <Router>
        {path === '/search' ? null : (
          <Navtab class='light' background='trans' />
        )}
        {/* <Navtab class='light' background='trans' />    */}
        <div id={enableCenterLayout(path) ? 'page-wrap' : ''}>
          <>
            <GiveLocation setPath={setPath} />
            <ScrollToTop>
              <Suspense fallback={<Fallback />}>
                <Switch>
                  <Route
                    exact
                    path='/search'
                    render={(props) => {
                      // ensure search page has location state
                      if (!props.location.state) {
                        return (
                          <Redirect
                            to={{
                              pathname: '/search',
                              state: {
                                queryString: '',
                                searchCategory: 'NonProfits'
                              }
                            }}
                          />
                        );
                      } else {
                        return <Search />;
                      }
                    }}
                  />
                  <Route exact path='/about' component={aboutPage} />
                  <Route exact path='/admin' component={Admin} />
                  <Route path='/complete-donation' component={CompleteDonation} />
                  <Route exact path='/find' component={Find} />
                  <Route
                    exact
                    path='/login'
                    render={(props) => <LoginPage {...props} mode='login' />}
                  />
                  <Route
                    exact
                    path='/signup/:type?'
                    render={(props) => (
                      <LoginPage {...props} setEmail={setEmail} mode='signup' />
                    )}
                  />
                  <Route
                    exact
                    path='/signup-thankyou/:section'
                    render={(props) => <ThankYou {...props} email={email} />}
                  />
                  <Route
                    exact
                    path='/donation/:id/:campaign'
                    component={DonationPage}
                  />
                  <Route exact path='/thankyou/' component={thankyouPage} />
                  <Route
                    exact
                    path='/nonprofit/events/:id'
                    component={NpEventsPage}
                  />
                  <Route exact path='/nonprofit/:id' component={NPProfile} />
                  <Route exact path='/manage-pay' component={ManagepayPage} />
                  <Route
                    exact
                    path='/event/tickets'
                    component={EventTicketOrderPage}
                  />
                  <Route exact path='/event/:id' component={EventPage} />
                  <Route exact path='/donor/:id' component={DonorProfile} />
                  <Route exact path='/404' component={NoMatch} />
                  <Route exact path='/confirm/:type/:id' component={Confirm} />
                  <Route exact path='/approve/:type/:id/' component={Approve} />
                  <Route exact path='/' component={Home} />
                  <Route exact path='/subscribe' component={Subscribe} />
                  <Route exact path='/forgot' component={forgot_PW_page} />
                  <Route exact path='/dashboard' render={dashAuth} />
                  {/* <Route exact path='/dashboard' render={dashAuth} /> */}
                  <Route
                    exact
                    path='/donationTracking'
                    component={DonorReport}
                  />
                  <Route
                    exact
                    path='/dashboard/:section/:error'
                    component={StripeFailure}
                  />
                  <Route exact path='/dashboard/:section' render={dashAuth} />
                  <Route exact path='/reset/:token' component={PwResetPage} />
                  <Route component={NoMatch} />

                  <ProtectedRoute
                    isAuthenticated={true}
                    isAllowed={true}
                    restrictedPath='/nonprofit/dashboard/:id'
                    authenticationPath='/'
                  />
                  {/* <ProtectedRoute
          isAuthenticated={true}
          isAllowed={true}
          restrictedPath='/DashboardPage/dashboard/ :id'
          authenticationPath='/'
        /> */}
                </Switch>
              </Suspense>
            </ScrollToTop>
            {/* <MobileNavbar /> */}

            {/* {mobileInstallMessage && (
              <MobileInstallPopup
                close={() => setMobileInstallMessage(false)}
              />
            )} */}
          </>
        </div>
      </Router>
    </div>
  );
};

export default App;
