const installPopupReducer = (state = 0, action: any) => {
  switch (action.type) {
    case 'increment':
      return ++state;
    default:
      return state;
  }
};

export default installPopupReducer;
