import { combineReducers } from 'redux';
// import signupTypeReducer from './signupType';
import signinUserReducer from './signinUser';
import signinOrgReducer from './signinOrg';
import installPopupReducer from './installPopup';
import saveSearchFiltersReducer from './searchPage';

//displaying the landing page welcome message
const displayingWelcomeMssgReducer = (state = {}, action: any) => {
  switch (action.type) {
    case 'HideWelcomeMessage':
      return (state = { show: false });
    default:
      return state;
  }
};

const allReducers = combineReducers({
  org: signinOrgReducer,
  signin: signinUserReducer,
  displayWelcomeMessage: displayingWelcomeMssgReducer,
  displayInstallPopup: installPopupReducer,
  saveSearchFilters: saveSearchFiltersReducer
  // signup: signupTypeReducer,
});

export default allReducers;
