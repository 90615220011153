import React, { useState } from 'react';
import API from '../../../utils/API';
import formatLink from '../../../utils/formatLink';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { saveOrg } from '../../../redux/actions';
import NeddieButton from '../../../components/neddieButton';
import './manageMarketPlacePopup.css';
import { Modal } from 'react-bootstrap';
import four from '../../../images/daskboard/4.png'

interface ISharingOption {
    marketPlace?: boolean; //to make the share symbol into a Neddie Button
    setMarketPlace?: (value: boolean) => void; //to make the share button light
  }

const ManageMarketPlacePopup: React.FC<ISharingOption> = ({marketPlace , setMarketPlace}) => {

  const reduxOrg = useSelector((state: any) => state.org);
  const [input, setInput] = useState({ _id: reduxOrg._id, marketplace: '' });
  const [submitting, setSubmitting] = useState(false);

  const dispatch = useDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInput((input) => ({
      ...input,
      [name]: value
    }));
  };

  const handleSubmit = async (event: any) => {
    console.log(event, input);
    event.preventDefault();
    setSubmitting(true);
    input.marketplace = formatLink(input.marketplace);
    const response: any = await API.saveMarketplace(input).catch((err: any) =>
      console.log(err)
    );
    console.log(response);

    if (response?.status == 200) {
      const newReduxOrg = { ...reduxOrg };
      newReduxOrg.actionItems.marketplace = input.marketplace;
      dispatch(saveOrg(newReduxOrg));
      setSubmitting(false);
      handleSetWishList()
    }
  };

  const shorten = (str: string) => {
    if (str.length <= 40) {
      return str;
    } else {
      return `${str.slice(0, 40)}...`;
    }
  };

  const handleSetWishList = () => {
    if (setMarketPlace) {
      setMarketPlace(!marketPlace);
    }
  };

  return (
    <Modal centered show={marketPlace} onHide={handleSetWishList} className='manage-wish-popups'>
      <div className='manage-wish-wrap'>
        <span className='cancle-popup' onClick={handleSetWishList}>x</span>
      <h1 className='manage-marketplace-h2'>Manage Marketplace <img src={four} style={{width:'26px' , marginTop : '-5px'}} alt="" /></h1>
      {reduxOrg.actionItems.marketplace ? (
          shorten(reduxOrg.actionItems.marketplace)
        ) : (
          <p>You currently don't have a marketplace.</p>
        )}
     
        <form className='manage-marketplace-addlist-form'>
          <label>Marketplace Link</label>
          <div className='manage-marketplace-addlist-input-row'>
            <input
              type='text'
              name='marketplace'
              placeholder='Paste your marketplace link here'
              onChange={handleChange}
              required
              value={input.marketplace || ''}
            />
          </div>

          <NeddieButton
            type='button'
            value={
              input.marketplace
                ? 'Update'
                : submitting
                ? 'Updating...'
                : 'Submit'
            }
            onClick={(e: any) => handleSubmit(e)}
          ></NeddieButton>
        </form>

    </div>
    </Modal>
  );
};

export default ManageMarketPlacePopup;
