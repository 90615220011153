import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import './dashboard.css';
import DasgboardIndividual from './DasgboardIndividual';
import DasgboardNonprofit from './DasgboardNonprofit';

const Dashboard: React.FC = (props: any) => {
  const logged = useSelector((state: any) => state.signin.type);

  useEffect(()=>{})

  return (
    <>
      {logged === 'individual' &&
        <DasgboardIndividual />
      }
      {logged === 'nonprofit' &&
        <DasgboardNonprofit />
      }

    </>
  );
};

export default Dashboard;
