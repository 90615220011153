import React, { useState, useEffect } from 'react';
import validation from '../../../components/Validator/validationRules';
import check from '../images/icons/check-circle-icon.svg';
import API from '../../../utils/API';
import PasswordValidationDisplay from '../settings/ValidationDisplay';
import { useSelector } from 'react-redux';


const Password: React.FC = () => {
  const [isSavepassword, setSavepassword] = useState(false);
  const [validationSuccess, setValidationSuccess] = useState(false);
  const redux = useSelector((state: any) => state);
  const reduxUser = redux.signin;

  const [input, setInput] = useState<{
    newPassword: string;
    currentPassword: string;
    confirmNewPassword: string;
  }>({
    newPassword: '',
    currentPassword: '',
    confirmNewPassword: ''
  });

  const [passwordValidator, setPasswordValidator] = useState({
    MinCharacterLength: false,
    Empty: false,
    NotPasswordMatch: false,
    currentPasswordNotMatch: false
  });

  // save password and toggle success message
  const savePassword = (e: any) => {
    // validate

    e.preventDefault();

    if (validationSuccess === false) {
      console.log('invalid password');
      return;
    }

    //check user
    API.checkPassword(reduxUser._id, input.currentPassword)
      .then((response) => {
        // console.log(response);
        if (response.data.passwordMatch === true) {
          // update password
          API.updatePassword(reduxUser._id, input.newPassword)
            .then((response) => {
              if (response.data.updated === true) {
                setSavepassword(true);
              } else {
                console.log('user not updated');
              }
            })
            .catch((error: any) => {
              console.log(error);
            });
        } else {
          // validation text ..current password wrong
          setPasswordValidator((v) => ({
            ...v,
            currentPasswordNotMatch: true
          }));
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  const handleChange = (e: any) => {
    e.persist();
    setInput((input) => ({
      ...input,
      [e.target.name]: e.target.value
    }));

    //validation
    if (e.target.name === 'newPassword') {
      if (validation.NotEmpty(e.target.value) === false) {
        setPasswordValidator((v) => ({
          ...v,
          Empty: true
        }));
      } else {
        setPasswordValidator((v) => ({
          ...v,
          Empty: false
        }));
      }
      if (validation.minStringLength(input.newPassword, 8) === false) {
        console.log('here');
        setPasswordValidator((v) => ({
          ...v,
          MinCharacterLength: true
        }));
      } else {
        setPasswordValidator((v) => ({
          ...v,
          MinCharacterLength: false
        }));
      }
      // let passwordVal = passwordValidator;
      // passwordVal.MinCharacterLength = validation.minStringLength(
      //   e.target.value,
      //   8
      // );
      // passwordVal.Empty = validation.NotEmpty(e.target.value);
      // if (
      //   passwordVal.MinCharacterLength === true &&
      //   passwordVal.Empty === true
      // ) {
      //   setPasswordValidator(passwordVal);
      // } else {
      //   setPasswordValidator((validator) => ({
      //     ...validator,
      //     MinCharacterLength: false,
      //     Empty: false,
      //   }));
      // }
    }
  };

  useEffect(() => {
    if (input.newPassword.length > 0) {
      if (input.confirmNewPassword !== input.newPassword) {
        setValidationSuccess(false);
        setPasswordValidator((v) => ({
          ...v,
          NotPasswordMatch: true
        }));
      } else {
        setValidationSuccess(true);
        setPasswordValidator((v) => ({
          ...v,
          NotPasswordMatch: false
        }));
      }
    }
  }, [input.confirmNewPassword]);

  return (

      <div className='pass-reset-deshboard'>
        <h1>Change Password</h1>
        {isSavepassword ? (
          <div className='change-password-success-container'  style={{marginInline: 'auto'}}>
            <p>Your password has been reset successfully!</p>
            <img src={check} alt='check' />
          </div>
        ) : !isSavepassword ? (
          <>
          <div className="contianer">
            <div className="row" style={{justifyContent:'center'}}>
              <div className="col-lg-6">
              <div className='change-password-content-inner'>
              <form className='change-password-form' style={{marginTop:'50px'}}>
                <p>Current Password</p>
                <input
                  type='password'
                  name='currentPassword'
                  required
                  onChange={handleChange}
                />
                <PasswordValidationDisplay
                  currentPasswordNotMatch={
                    passwordValidator.currentPasswordNotMatch
                  }
                  TextValidation_Input={{ input: 'newPassword', minLength: 8 }}
                />
                <p>New Password</p>
                <input
                  type='password'
                  name='newPassword'
                  required
                  onChange={handleChange}
                />
                <PasswordValidationDisplay
                  MinCharacterLength={passwordValidator.MinCharacterLength}
                  Empty={passwordValidator.Empty}
                  TextValidation_Input={{ input: 'newPassword', minLength: 8 }}
                />
                <p>Retype New Password</p>
                <input
                  type='password'
                  name='confirmNewPassword'
                  required
                  onChange={handleChange}
                />
                <PasswordValidationDisplay
                  NotPasswordMatch={passwordValidator.NotPasswordMatch}
                  TextValidation_Input={{ input: 'confirmNewPassword' }}
                />
                <div className='change-password-btn-container'>
                  <button
                    className='change-password-btn'
                    type='submit'
                    onClick={savePassword}
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
              </div>
            </div>
          </div>
           
          </>
        ) : null}
      </div>
  );
};

export default Password;
