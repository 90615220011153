import React, { useState } from 'react';
import { GoSearch } from 'react-icons/go';
import { InputGroup, FormControl } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { RouteComponentProps, withRouter } from 'react-router';
import { useSelector } from 'react-redux';

import './SearchBar.css';

interface Props extends RouteComponentProps {}

interface IInput {
  queryString?: string;
}

const SearchBar = ({ history }: Props) => {
  const [input, setInput] = useState<IInput>({});
  const reduxUser = useSelector((state: any) => state.signin);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInput((input) => ({
      ...input,
      [name]: value
    }));
  };

  const onKeyUp = (event: any) => {
    event.persist();
    if (event.charCode === 13) {
      history.push({
        pathname: '/search',
        state: { ...input, searchCategory: 'NonProfits' }
      });
    }
  };
  return (
    <div
      id='searchbarOuter'
      className={reduxUser.type === 'individual' ? 'searchbar-outer-donor' : ''}
    >
      <InputGroup>
        <FormControl
          className='searchbar'
          onKeyPress={onKeyUp}
          onChange={handleChange}
          name='queryString'
          placeholder='Search by cause, location, or keyword'
          style={{ padding: '.5vw 1vw .5vw 2vw' }}
          tabIndex={-1}
        />
        <Link to={{ pathname: '/search', state: { ...input, searchCategory: 'NonProfits' }}} data-testid='search-icon'>
          <InputGroup.Text className='searchIcon searchIcon-desktop' id='basic-addon2'>
            <GoSearch />
          </InputGroup.Text>
        </Link>
      </InputGroup>
    </div>
  );
};

export default withRouter(SearchBar);

// export default SearchBar;
