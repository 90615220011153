import React, { useEffect, useState } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import BottomNav from './bottomNav/DashboardBottomNav';
import DesktopInstallPopup from './desktopInstallPopup';
import { useSelector } from 'react-redux';
import windows from '../../images/daskboard/window.png'
import one from '../../images/daskboard/1.png'
import two from '../../images/daskboard/2.png'
import three from '../../images/daskboard/3.png'
import four from '../../images/daskboard/4.png'
import five from '../../images/daskboard/5.png'
import six from '../../images/daskboard/6.png'
import seven from '../../images/daskboard/7.png'
import eight from '../../images/daskboard/8.png'
import nine from '../../images/daskboard/9.png'
import ten from '../../images/daskboard/10.png'
import eleven from '../../images/daskboard/11.png'
import sixteen from '../../images/daskboard/16.png'
import './dashboard.css';
import AvtiveFundraisersprogress from './avtiveFundraisersprogress/AvtiveFundraisersprogress';
import ManageWishlistPopup from './manageWishlistPopup/ManageWishlistPopup';
import ManageMarketPlacePopup from './manageMarketPlacePopup/ManageMarketPlacePopup';
import QuickSharePopup from './quickSharePopup/QuickSharePopup';
import WhatsNewPopup from './whatsNewPopup/WhatsNewPopup';
import DashboardSetting from './dashboardSetting/DashboardSetting';
import ManagePay from './dashboardSetting/ManagePay';
import { useHistory } from 'react-router-dom';
import GoToDaskboardPopup from './goToDaskboardPopup/GoToDaskboardPopup';
import AcountMobile from './dashboardSetting/AccountMobile';
import PasswordMobile from './dashboardSetting/PasswordMobile';

const DasgboardNonprofit: React.FC = (props: any) => {
  const [wishList, setWishList] = useState(false);
  const [marketPlace, setMarketPlace] = useState(false);
  const [shareLink, setShareLink] = useState(false);
  const [whatsNew, setWhatsNew] = useState(false);
  const [goToDaskboardPopup, setGoToDaskboardPopup] = useState(false);
  const [mobileSettingTab, setMobileSettingTab] = useState(0);
  const [dashboardSetting, setDashboardSetting] = useState(false);
  const redux = useSelector((state: any) => state);
  const reduxOrg = useSelector((state: any) => state.org);
  const history = useHistory();

  const goToProfile = () => {
    history.push(`/nonprofit/${reduxOrg._id}?sec=fundraser`);
  };
  const goToProfilee = () => {
    history.push(`/nonprofit/${reduxOrg._id}`);
  };
  const goToManagepay = () => {
    history.push(`/manage-pay`);
  };


  const [desktopInstallMessage, setDesktopInstallMessage] = useState(false);
  const reduxInstallPrompt = useSelector(
    (state: any) => state.displayInstallPopup
  );
  useEffect(() => {
    const isDesktop = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();

      return /Mozilla5.0 (Linux; Android 8.0; Pixel 2 Build|OPD3.170816.012) AppleWebKit|537.36 (KHTML, like Gecko) Chrome|86.0.4240.198 Mobile Safari|537.36/.test(
        userAgent
      );
    };

    // Detects if device is in standalone mode
    // const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);
    const newNavigator: any = window.navigator;
    const isInStandaloneMode = () => newNavigator.standalone;

    // Checks if should display install popup notification:

    if (isDesktop() && !isInStandaloneMode() && !(reduxInstallPrompt % 5)) {
      setDesktopInstallMessage(true);
    }
  }, []);




  return (
    <>
          <BrowserView style={{ height: '100%' }}>
            <div className="new-dashboard-layout">
              <div className="container p-0" style={{ maxWidth: '1270px' }}>
                <div className="dashboard-sec-1">
                  <div className="title">
                    <h1>{redux?.org?.name}</h1>
                  </div>
                  <div className="dashboard">
                    <img src={windows} alt="" />
                    <span>Dashboard</span>
                  </div>
                </div>
                <div className="dashboard-sec-2">
                  <p className='need-help'>
                    <a href="https://www.neddieblog.com/get-help" target='_blank'>
                      <p >Need Help?</p>
                    </a>
                    <span>i</span>
                  </p>
                  <div className="sec-2-wrap">
                    <div onClick={goToProfilee}>
                      <img src={one} alt="" />
                      <p>Edit My Profile</p>
                    </div >
                    <div onClick={goToManagepay}>
                      <img src={two} alt="" />
                      <p>Manage Pay</p>
                    </div>
                    <div onClick={() => setWishList(true)}>
                      <img src={three} alt="" />
                      <p>Manage Wishlist</p>
                    </div>
                    <div onClick={() => setMarketPlace(true)}>
                      <img src={four} alt="" />
                      <p>Manage Marketplace</p>
                    </div>
                    <div>
                      <a href="https://www.neddieblog.com/grant-central-search" target='_blank'>
                        <img src={five} alt="" />
                        <p>Search For Grants</p>
                      </a>
                    </div>
                    <div>
                      <a href="https://www.neddieblog.com/neddie-forum" target='_blank'>
                        <img src={sixteen} alt="" />
                        <p>Community Forum</p>
                      </a>
                    </div>
                    <div onClick={() => setShareLink(true)}>
                      <img src={six} alt="" />
                      <p>Your Links</p>
                    </div>
                    <div onClick={() => setWhatsNew(true)}>
                      <img src={seven} alt="" />
                      <p>What’s New</p>
                    </div>
                    <div onClick={() => { setDashboardSetting(true) }}>
                      <img src={eight} alt="" />
                      <p>Account Settings</p>
                    </div>
                  </div>
                </div>
                <div className="dashboard-sec-3">
                  <div className="left">
                    <ManagePay />
                  </div>
                  <div className="right">
                    <div className="top">
                      <AvtiveFundraisersprogress />
                    </div>
                    <div className="bottom">
                      <h3>Raise Funds</h3>
                      <div className="rase-found-wrap">
                        <div onClick={goToProfile}>
                          <img src={nine} alt="" />
                          <p>Create Fundraiser</p>
                        </div>
                        <div>
                          <a href="https://www.neddieblog.com/influencers-for-good" target='_blank'>
                            <img src={ten} alt="" />
                            <p>Collaborate With Influencers</p>
                          </a>
                        </div>
                        <div >
                          <a href="https://www.neddieblog.com/grant-writers" target='_blank'>
                            <img src={eleven} alt="" />
                            <p>Connect With Grant Writers</p>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="dashboard-footer">
              <div className="dashboard-copyright">
                <p>© {new Date().getFullYear()} Neddie, all rights reserved</p>
              </div>

              <div className="dashboard-bootom-nav">
                <BottomNav />
              </div>
            </div>


            {/* </div>
        </div>  */}


            {desktopInstallMessage && (
              <DesktopInstallPopup close={() => setDesktopInstallMessage(false)} />
            )}
          </BrowserView>
          <MobileView>
            {/* <MobileDashboard /> */}
            <div className="mobile-dashboard" style={{ backgroundColor: mobileSettingTab === 0 ? 'rgb(72, 116, 205)' : '#fff' }}>
              <div className="dashboard-sec-1">
                <div className="top">

                  <div className="title">
                    <h1>{redux?.org?.name}</h1>
                  </div>
                  <div className="dashboard">
                    <img src={windows} alt="" />
                    <span>Dashboard</span>
                  </div>
                </div>
                <div className="bottom">
                  <p className='need-help'>
                    <a href="https://www.neddieblog.com/get-help" target='_blank'>
                      <p >Need Help?</p>
                    </a>
                    <span>i</span>
                  </p>
                </div>
                <div className="cricle-1"></div>
                <div className="cricle-2"></div>
              </div>

              {mobileSettingTab === 0 &&
                <>
                  <div className="dashboard-sec-2">
                    <p>
                      To access <span>all</span>  features please use the dashboard on your desktop
                      <div className="cricle-1"></div>
                      <div className="cricle-2"></div>
                      <div className="cricle-3"></div>
                      <div className="cricle-4"></div>
                      <div className="cricle-5"></div>
                    </p>
                  </div>
                  <div className="dsahboard-sec-3">
                    <div className="sec-2-wrap">
                      <div className="row">
                        <div className="col-4 px-2 mb-3">
                          <div onClick={() => setGoToDaskboardPopup(true)}>
                            <img src={one} alt="" style={{ width: '35px' }} />
                            <p>Edit My Profile</p>
                          </div ></div>
                        <div className="col-4 px-2 mb-3">
                          <div onClick={() => setGoToDaskboardPopup(true)}>
                            <img src={two} alt="" style={{ width: '35px' }} />
                            <p>Manage Pay</p>
                          </div>
                        </div>
                        <div className="col-4 px-2 mb-3">
                          <div onClick={() => setWishList(true)}>
                            <img src={three} alt="" style={{ width: '25px' }} />
                            <p>Manage Wishlist</p>
                          </div>
                        </div>
                        <div className="col-4 px-2 mb-3">
                          <div onClick={() => setMarketPlace(true)}>
                            <img src={four} alt="" style={{ width: '35px' }} />
                            <p>Manage Marketplace</p>
                          </div>
                        </div>
                        <div className="col-4 px-2 mb-3">
                          <div>
                            <a href="https://www.neddieblog.com/grant-central-search" target='_blank'>
                              <img src={five} alt="" style={{ width: '35px' }} />
                              <p>Search For Grants</p>
                            </a>
                          </div>
                        </div>
                        <div className="col-4 px-2 mb-3">
                          <div>
                            <a href="https://www.neddieblog.com/neddie-forum" target='_blank'>
                              <img src={sixteen} alt="" style={{ width: '55px' }} />
                              <p>Community Forum</p>
                            </a>
                          </div>
                        </div>
                        <div className="col-4 px-2 mb-3">
                          <div onClick={() => setShareLink(true)}>
                            <img src={six} alt="" style={{ width: '45px' }} />
                            <p>Your Links</p>
                          </div>
                        </div>
                        <div className="col-4 px-2 mb-3">
                          <div onClick={() => setGoToDaskboardPopup(true)}>
                            <img src={nine} alt="" style={{ width: '43px' }} />
                            <p>Create Fundraiser</p>
                          </div>
                        </div>
                        <div className="col-4 px-2 mb-3">
                          <div>
                            <a href="https://www.neddieblog.com/influencers-for-good" target='_blank'>
                              <img src={ten} alt="" style={{ width: '35px' }} />
                              <p>Collaborate With Influencers</p>
                            </a>
                          </div>
                        </div>
                        <div className="col-4 px-2 mb-3">
                          <div >
                            <a href="https://www.neddieblog.com/grant-writers" target='_blank'>
                              <img src={eleven} alt="" style={{ width: '35px' }} />
                              <p>Connect With Grant Writers</p>
                            </a>
                          </div>
                        </div>
                        <div className="col-4 px-2 mb-3">
                          <div onClick={() => setWhatsNew(true)}>
                            <img src={seven} alt="" style={{ width: '45px' }} />
                            <p>What’s New</p>
                          </div>
                        </div>
                        <div className="col-4 px-2 mb-3">
                          <div onClick={() => { setDashboardSetting(true) }}>
                            <img src={eight} alt="" style={{ width: '33px' }} />
                            <p>Account Settings</p>
                          </div>
                        </div>
                      </div>








                    </div>
                  </div>
                </>
              }
              {mobileSettingTab === 1 &&
                <>
                  <AcountMobile setMobileSettingTab={setMobileSettingTab} />
                </>
              }
              {mobileSettingTab === 2 &&
                <>
                  <PasswordMobile setMobileSettingTab={setMobileSettingTab} />
                </>
              }
            </div>
            <div className="dashboard-footer">
              <div className="dashboard-copyright">
                <p>© {new Date().getFullYear()} Neddie, all rights reserved</p>
              </div>

              <div className="dashboard-bootom-nav">
                <BottomNav />
              </div>
            </div>
          </MobileView >
          <ManageWishlistPopup wishList={wishList} setWishList={setWishList} />
          <GoToDaskboardPopup goToDaskboardPopup={goToDaskboardPopup} setGoToDaskboardPopup={setGoToDaskboardPopup} />
          <ManageMarketPlacePopup marketPlace={marketPlace} setMarketPlace={setMarketPlace} />
          <QuickSharePopup shareLink={shareLink} setShareLink={setShareLink} />
          <WhatsNewPopup whatsNew={whatsNew} setWhatsNew={setWhatsNew} />
          <DashboardSetting dashboardSetting={dashboardSetting} setDashboardSetting={setDashboardSetting} mobileSettingTab={mobileSettingTab} setMobileSettingTab={setMobileSettingTab} />
    </>
  );
};

export default DasgboardNonprofit;
