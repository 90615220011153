import React from 'react';
import { Link } from 'react-router-dom';
import './style.css';
import share from './images/share.svg';

const NeddieButton: React.FC<{
  onClick?: any; //click event
  onChange?: any; //keystroke event
  type: 'anchor' | 'link' | 'button' | 'disabled' | 'share'; //anchor: link away from NEDDIE | link: navigate within NEDDIE | button: button to for click events | disabled: a disabled button | share: this type is reserved for sharingOptions component
  src?: any; //where the link or anchor will navigate
  value: any; //text that appears on the button
  isLight?: boolean; //true for white button
  isSquare?: boolean; //true for 1px radius edge
  className?: string; //add your own class names if necessary
  openInNewTab?: boolean; //do you want this to open in a new tab?
}> = ({
  src,
  value,
  isLight,
  type,
  onClick,
  onChange,
  className,
  isSquare,
  openInNewTab
}) => {
  if (type === 'link') {
    //navigate within platform
    return (
      <Link
        className='button-link'
        to={!src ? '/404' : src}
        target={openInNewTab ? '_blank' : ''}
      >
        <button
          //className={isLight ? 'neddie-button neddie-light' : 'neddie-button'}
          className={`neddie-button ${isLight ? 'neddie-light' : ''} ${
            className ? className : ''
          } ${isSquare && 'neddie-button-squared'}`}
          onClick={onClick}
          onChange={onChange}
        >
          {value}
        </button>
      </Link>
    );
  } else if (type === 'anchor') {
    //navigate away from platform
    return (
      <a
        className={`neddie-button ${isLight ? 'neddie-light' : ''} ${
          className ? className : ''
        } ${isSquare ? 'neddie-button-squared' : ''}`}
        href={src}
        target='_blank'
        rel='noopener noreferrer'
      >
        {value}
      </a>
    );
  } else if (type === 'disabled') {
    //neddie button but disabled
    return (
      <button
        disabled
        className={`neddie-button-disabled ${className ? className : ''} ${
          isSquare && 'neddie-button-squared'
        }`}
      >
        {value}
      </button>
    );
  } else if (type === 'share') {
    //used in the share component for the isButton prop
    return (
      <button
        className={`neddie-button neddie-share-button ${
          isLight ? 'neddie-light neddie-share-button-light' : ''
        } ${className ? className : ''} ${isSquare && 'neddie-button-squared'}`}
        onClick={onClick}
        onChange={onChange}
      >
        {value} <img src={share}></img>
      </button>
    );
  } else {
    //fire onClick funtion (submit, continue, yes/no etc.) 'button'
    return (
      <button
        className={`neddie-button ${isLight ? 'neddie-light' : ''} ${
          className ? className : ''
        } ${isSquare && 'neddie-button-squared'}`}
        onClick={onClick}
        onChange={onChange}
      >
        {value}
      </button>
    );
  }
};

export default NeddieButton;
