export const signupType = (input: any) => {
  return {
    type: 'signup/addType',
    payload: input
  };
};

export const saveUser = (input: any) => {
  return {
    type: 'signin/saveUser',
    payload: input
  };
};

export const deleteUser = () => {
  return {
    type: 'signin/deleteUser'
  };
};

export const saveOrg = (input: any) => {
  return {
    type: 'signin/saveOrg',
    payload: input
  };
};

export const deleteOrg = () => {
  return {
    type: 'signin/deleteOrg'
  };
};

export const HideWelcomeMessage = () => {
  return {
    type: 'HideWelcomeMessage'
  };
};

export const incrementInstallPopup = () => {
  return {
    type: 'increment'
  };
};
export const saveSearchFilters = (input: any) => {
  return {
    type: 'saveSearchFilters',
    payload: input
  };
};
