import React, { useState, useEffect } from 'react';
import API from '../../../utils/API';
import { Modal } from 'react-bootstrap';
import seven from '../../../images/daskboard/7.png'
import './whatsNewPopup.css';

interface ISharingOption {
  whatsNew?: boolean; //to make the share symbol into a Neddie Button
  setWhatsNew?: (value: boolean) => void; //to make the share button light
}

const WhatsNewPopup: React.FC<ISharingOption> = ({ whatsNew, setWhatsNew }) => {
  const [showUpdates, setShowUpdates] = useState<any>([]);

  useEffect(() => {
    API.getUpdate().then((result) => {
      setShowUpdates(
        result.data.sort((a: any, b: any) => b.createdAt - a.createdAt)
      );
    });
  }, []);

  const handleSetWishList = () => {
    if (setWhatsNew) {
      setWhatsNew(!whatsNew);
    }
  };

  return (
    <Modal centered show={whatsNew} onHide={handleSetWishList} className='whats-new-popups'>
      <div className='manage-wish-wrap'>
        <span className='cancle-popup' onClick={handleSetWishList}>x</span>
        <div className='dashBoard-infocard-top-row'>
          <h2 className='dashboard-info-h2'>What’s New <img src={seven} style={{width:'30px' , marginTop : '-4px'}} alt="" /></h2>
        </div>

          <div className='dashboard-update-boxes-container'>
            {showUpdates.map((up: any, i: number) => {
              return (
                <div
                  className={
                    i % 2 !== 0
                      ? 'dashboard-update-row'
                      : 'dashboard-update-row'
                  }
                >
                  <div className='dashboard-update-background-box'>
                    <div className='dashboard-update-title'>
                      {up.title}
                    </div>
                    <div className='dashboard-update-description'>
                      {up.description}
                    </div>
                    <div className='dashboard-update-link'>{up.link}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

    </Modal>
  );
};

export default WhatsNewPopup;
