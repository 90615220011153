import React from 'react';
import './wwoCard.css';

function WWOCard(props: any) {
  return (
    <div className='what-we-offer-card'>
      <img alt='oops' src={props.img} className='wwo-img'></img>
      <div className='wwo-card-content-container'>
        <div className='wwo-title'>{props.title}</div>
        <div className='wwo-desc'>{props.desc}</div>
      </div>
    </div>
  );
}

export default WWOCard;
