import React, { useState, useRef, ChangeEvent, useEffect } from 'react';
import Script from 'react-load-script';
require('dotenv').config();
const LocationInput = (props: {
  name: string;
  placeholder: string;
  updatedLocation: any;
  savedAddress: {
    city: string;
    state: string;
  };
}) => {
  const autoCompleteObject = useRef<HTMLInputElement>(null);
  let autocomplete: any;
  const googleMapApi =
    'https://maps.googleapis.com/maps/api/js?key=' +
    'AIzaSyDOiym6TNyqJ5IxLUKdJo-h1-gqrrEc8zE' +
    '&libraries=places';

  const [apiLoaded, setApiLoaded] = useState(false);
  const [inputLocationValue, setInputLocationValue] = useState(
    props.savedAddress.city !== ''
      ? props.savedAddress.city + ', ' + props.savedAddress.state
      : ''
  );
  const [userAddress, setUserAddress] = useState({
    city: props.savedAddress.city,
    state: props.savedAddress.state
  });

  const FullAddressHandleSelect = () => {
    const addressObject = autocomplete.getPlace();
    // console.log(addressObject)
    let address = {
      city: '',
      state: ''
    };
    // console.log(addressObject)

    try {
      addressObject.address_components.map((x: any) => {
        if (x.types[0] === 'locality') {
          address['city'] = x.long_name;
        }
        if (x.types[0] === 'administrative_area_level_1') {
          address['state'] = x.short_name;
        }
        return x;
      });
    } catch {
      address.city = addressObject.name;
    }
    // console.log("this the the address object we are sending-->>", address)
    setInputLocationValue(addressObject.formatted_address);
    setUserAddress({ ...address });
  };
  const handleAddressChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setInputLocationValue(e.target.value);
    setUserAddress({ city: e.target.value, state: '' });
  };

  useEffect(() => {
    props.updatedLocation(userAddress);
  }, [userAddress]);

  const handleScriptLoad = () => {
    // console.log("the autoComplete object-->>", autoCompleteObject)
    if (autoCompleteObject.current) {
      // restrictions on what gogole api should look for
      // we use 'regions' as the type, becuase it provides both city/state and zipcode (if any)
      const options = {
        types: [`(regions)`],
        componentRestrictions: {
          country: 'us'
        }
      };
      /*global google*/
      autocomplete = new google.maps.places.Autocomplete(
        autoCompleteObject.current,
        options
      );
      //use setFields to customize what results we want to receive
      autocomplete.setFields(['formatted_address', 'address_components']);
      //when user select an address
      autocomplete.addListener('place_changed', FullAddressHandleSelect);
      setApiLoaded(true);
    } else {
      console.log('auto-complete object no found');
    }
  };

  return (
    <>
      <input
        ref={autoCompleteObject}
        type='text'
        name={props.name}
        placeholder={
          apiLoaded === true
            ? props.placeholder
            : 'error occured: google place api was not loaded.'
        }
        onChange={handleAddressChange}
        value={inputLocationValue || ''}
        className='donorSetting-locationAutoComplete-Input'
      />

      <Script
        url={googleMapApi}
        onLoad={handleScriptLoad}
        onError={() => {
          console.log('Error Loading Google Map Script....');
        }}
      ></Script>
    </>
  );
};

export default LocationInput;
