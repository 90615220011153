import React, { useEffect, useState } from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { IUserPreferences } from './DonorProfile';
import LocationInput from './locationInput';
import TelephoneInput from './telephoneInput';

export enum ageRange {
  Under17 = 0,
  Between18And34 = 1,
  Between35And54 = 2,
  Between55And74 = 3,
  Over74 = 4
}

const UserPreferences = (props: {
  preferences: IUserPreferences;
  userName: string;
  email: string;
  setUserPreferences: any;
  setUserValidation: any;
}) => {
  const [showOtherGenderInput, setShowOtherGenderInput] = useState(false);

  //update component if preferences changes
  useEffect(() => {
    //we render the "OtherGenderInput if the user's gender is "other" or is not male,female,non-binary and the gender value is not empty "
    (props.preferences.otherGender === 'other' ||
      (props.preferences.otherGender !== 'male' &&
        props.preferences.otherGender !== 'female' &&
        props.preferences.otherGender !== 'non-binary')) &&
      props.preferences.otherGender !== '' &&
      setShowOtherGenderInput(true);

    // setCurrentPreferences({...props.preferences});
  }, [props.preferences]);

  const handleClick = (e: any) => {
    e.persist();
    let newPreferences = props.preferences;

    switch (e.target.dataset.name) {
      case 'genderInput':
        newPreferences.demographic.gender = e.target.dataset.gender;
        newPreferences.otherGender = '';
        //hide and show the "otherGender input"
        e.target.dataset.gender === 'other'
          ? setShowOtherGenderInput(true)
          : setShowOtherGenderInput(false);
        break;

      case 'ageInput':
        newPreferences.demographic.age = parseInt(e.target.dataset.age);
        break;

      default:
        break;
    }
    props.setUserPreferences(newPreferences);
  };

  const handleChange = (e: any) => {
    e.persist();
    const { name, value } = e.target;

    //Preferences Visibility logic
    let newPreferences = props.preferences;

    switch (name) {
      case 'namePrivacyCheckbox':
        newPreferences.ShareMyPrivateInfo.name =
          !props.preferences.ShareMyPrivateInfo.name;
        break;
      case 'genderPrivacyCheckbox':
        newPreferences.ShareMyPrivateInfo.gender =
          !props.preferences.ShareMyPrivateInfo.gender;
        break;
      case 'agePrivacyCheckbox':
        newPreferences.ShareMyPrivateInfo.age =
          !props.preferences.ShareMyPrivateInfo.age;
        break;
      case 'emailPrivacyCheckbox':
        newPreferences.ShareMyPrivateInfo.email =
          !props.preferences.ShareMyPrivateInfo.email;
        break;
      case 'phonePrivacyCheckbox':
        newPreferences.ShareMyPrivateInfo.telephone =
          !props.preferences.ShareMyPrivateInfo.telephone;
        break;
      case 'locationPrivacyCheckbox':
        newPreferences.ShareMyPrivateInfo.location =
          !props.preferences.ShareMyPrivateInfo.location;
        break;
      case 'otherGenderInput':
        newPreferences.otherGender = value;
        newPreferences.demographic.gender = value;
        break;
      case 'phoneInput':
        newPreferences.telephone = value;
        break;
      default:
        break;
    }
    props.setUserPreferences(newPreferences);
  };

  const handleLocationChange = (address: { city: string; state: string }) => {
    props.setUserPreferences({ ...props.preferences, address });
  };
  const handleAddressChange = (phoneNumber: string) => {
    props.setUserPreferences({ ...props.preferences, telephone: phoneNumber });
  };
  return (
    <div className='UserPreferenceSection'>
      <hr />
      <h4>PREFERENCES</h4>

      <small>
        <strong className='text-secondary'>
          When you donate the information you check below will be shared with
          the nonprofits in order to improve their operations{' '}
        </strong>
      </small>
      <div className='mt-3 mb-5'>
        <Form.Group>
          <Form.Label>Name</Form.Label>
          <Row>
            <Col md={1} sm={1} xs={1} className='pr-1'>
              <Form.Group
                controlId='ShareNameCheckoutbox'
                className='text-center'
              >
                <Form.Check
                  type='checkbox'
                  checked={props.preferences.ShareMyPrivateInfo.name}
                  name='namePrivacyCheckbox'
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={11} sm={11} xs={11} className='pl-1'>
              <Form.Control
                style={{ textTransform: 'capitalize' }}
                type='text'
                placeholder='name'
                readOnly
                value={props.userName}
                className='donorSetting-preferenceInput UneditablePreferenceInput'
              />
            </Col>
          </Row>
        </Form.Group>
        <Form.Group>
          <Form.Label>Email</Form.Label>
          <Row>
            <Col md={1} sm={1} xs={1} className='pr-1'>
              <Form.Group
                controlId='ShareEmailCheckoutbox'
                className='text-center'
              >
                <Form.Check
                  type='checkbox'
                  checked={props.preferences.ShareMyPrivateInfo.email}
                  name='emailPrivacyCheckbox'
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={11} sm={11} xs={11} className='pl-1'>
              <Form.Control
                type='text'
                placeholder='email'
                readOnly
                value={props.email}
                className='donorSetting-preferenceInput UneditablePreferenceInput'
              />
            </Col>
          </Row>
        </Form.Group>
        <Form.Group>
          <Form.Label>Location</Form.Label>
          <Row>
            <Col md={1} sm={1} xs={1} className='pr-1'>
              <Form.Group
                controlId='ShareLocationCheckoutbox'
                className='text-center'
              >
                <Form.Check
                  type='checkbox'
                  checked={props.preferences.ShareMyPrivateInfo.location}
                  name='locationPrivacyCheckbox'
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={11} sm={11} xs={11} className='pl-1'>
              <LocationInput
                name='locationInput'
                placeholder='Enter a location'
                savedAddress={props.preferences.address}
                updatedLocation={handleLocationChange}
              />
            </Col>
          </Row>
        </Form.Group>
        <Form.Group>
          <Form.Label>Phone</Form.Label>
          <Row>
            <Col md={1} sm={1} xs={1} className='pr-1'>
              <Form.Group
                controlId='SharePhoneCheckoutbox'
                className='text-center'
              >
                <Form.Check
                  type='checkbox'
                  checked={props.preferences.ShareMyPrivateInfo.telephone}
                  name='phonePrivacyCheckbox'
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={11} sm={11} xs={11} className='pl-1'>
              <TelephoneInput
                placeholder='Enter a phone number'
                name='phoneInput'
                value={props.preferences.telephone}
                handleChange={handleAddressChange}
                setUserValidation={props.setUserValidation}
              />
            </Col>
          </Row>
        </Form.Group>
        <Form.Group>
          <Form.Label>Gender</Form.Label>
          <Row>
            <Col md={1} sm={1} xs={1} className='pr-1'>
              <Form.Group
                controlId='ShareGenderCheckoutbox'
                className='text-center mb-0'
              >
                <Form.Check
                  type='checkbox'
                  checked={props.preferences.ShareMyPrivateInfo.gender}
                  name='genderPrivacyCheckbox'
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={10} sm={11} xs={11} className='pl-1'>
              <Row className='donorSetting-PreferenceSelector'>
                {['female', 'male', 'non-binary', 'other'].map(
                  (gender: string) => {
                    return (
                      <Col
                        key={gender}
                        className={
                          (gender === 'other' &&
                            props.preferences.otherGender !== '') ||
                          gender === props.preferences.demographic.gender
                            ? 'donorSetting-PreferenceSelected'
                            : ''
                        }
                        data-gender={gender}
                        data-name='genderInput'
                        onClick={(e: any) => handleClick(e)}
                      >
                        {gender}
                      </Col>
                    );
                  }
                )}
                {
                  // ((props.preferences.gender==="other" || (props.preferences.gender!=="male"&&props.preferences.gender!=="female"&&props.preferences.gender!=="non-binary"))&&props.preferences.gender!=="") &&
                  showOtherGenderInput === true && (
                    <Col
                      className='pl-0 pr-0'
                      id='donorSetting-OtherGenderInput'
                    >
                      <Form.Control
                        name='otherGenderInput'
                        type='text'
                        value={props.preferences.otherGender}
                        onChange={handleChange}
                        placeholder='gender...'
                      />
                    </Col>
                  )
                }
              </Row>
            </Col>
          </Row>
        </Form.Group>

        <Form.Group className='donorSetting-AgePreferenceSection'>
          <Form.Label>Age</Form.Label>
          <Row>
            <Col md={1} sm={1} xs={1} className='pr-1'>
              <Form.Group
                controlId='ShareAgeCheckoutbox'
                className='text-center mb-0'
              >
                <Form.Check
                  type='checkbox'
                  checked={props.preferences.ShareMyPrivateInfo.age}
                  name='agePrivacyCheckbox'
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={11} sm={11} xs={11} className='pl-1'>
              <Row className='donorSetting-PreferenceSelector'>
                <Col
                  className={
                    props.preferences.demographic.age === ageRange.Under17
                      ? 'donorSetting-PreferenceSelected p-2'
                      : 'p-2'
                  }
                  data-age={ageRange.Under17}
                  data-name='ageInput'
                  onClick={(e: any) => handleClick(e)}
                >
                  Under 17
                </Col>
                <Col
                  className={
                    props.preferences.demographic.age ===
                    ageRange.Between18And34
                      ? 'donorSetting-PreferenceSelected'
                      : ''
                  }
                  data-age={ageRange.Between18And34}
                  data-name='ageInput'
                  onClick={(e: any) => handleClick(e)}
                >
                  18-34
                </Col>
                <Col
                  className={
                    props.preferences.demographic.age ===
                    ageRange.Between35And54
                      ? 'donorSetting-PreferenceSelected'
                      : ''
                  }
                  data-age={ageRange.Between35And54}
                  data-name='ageInput'
                  onClick={(e: any) => handleClick(e)}
                >
                  35-54
                </Col>
                <Col
                  className={
                    props.preferences.demographic.age ===
                    ageRange.Between55And74
                      ? 'donorSetting-PreferenceSelected'
                      : ''
                  }
                  data-age={ageRange.Between55And74}
                  data-name='ageInput'
                  onClick={(e: any) => handleClick(e)}
                >
                  55-74
                </Col>
                <Col
                  className={
                    props.preferences.demographic.age === ageRange.Over74
                      ? 'donorSetting-PreferenceSelected'
                      : ''
                  }
                  data-age={ageRange.Over74}
                  data-name='ageInput'
                  onClick={(e: any) => handleClick(e)}
                >
                  Over 74
                </Col>
              </Row>
            </Col>
          </Row>
        </Form.Group>
      </div>
    </div>
  );
};

export default UserPreferences;
