import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

const loadingSpinner = () => {
  return (
    <div className='text-center mt-3'>
      <Spinner animation='border' role='status'>
        <span className='sr-only'>Loading...</span>
      </Spinner>
    </div>
  );
};

export default loadingSpinner;
