import { SearchProps } from '../../pages/searchPage/searchPageTypes';

//saving the search result in the search page
const defaultSearchFilters: SearchProps = {
  queryString: '',
  nonProfitAdvanceFilters: {
    category: '',
    city: '',
    state: '',
    zipcode: 0,
    coordinates: { lng: undefined, lat: undefined },
    active_campaign: false,
    domestic: false,
    international: false,
    distance: 0
  },
  campaignAdvanceFilters: {
    topic: '',
    startDate: '',
    endDate: ''
  },
  eventAdvanceFilters: {
    city: '',
    state: '',
    topic: '',
    startDate: '',
    endDate: ''
  },
  showAllResults: false,
  searchCategory: ''
};

const saveSearchFiltersReducer = (
  state = defaultSearchFilters,
  action: any
) => {
  switch (action.type) {
    case 'saveSearchFilters':
      return {
        ...state,
        ...action.payload
      };
    case 'clearSearchFilters':
      return {
        ...defaultSearchFilters
      };
    default:
      return state;
  }
};
export default saveSearchFiltersReducer;
