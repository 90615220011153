import React, { useState } from 'react';
import API from '../../../utils/API';
import formatLink from '../../../utils/formatLink';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { saveOrg } from '../../../redux/actions';
import NeddieButton from '../../../components/neddieButton';
import './manageWishlistPopup.css';
import { Modal } from 'react-bootstrap';
import three from '../../../images/daskboard/3.png'

interface ISharingOption {
  wishList?: boolean; //to make the share symbol into a Neddie Button
  setWishList?: (value: boolean) => void; //to make the share button light
}

const ManageWishlistPopup: React.FC<ISharingOption> = ({wishList , setWishList}) => {
  const reduxOrg = useSelector((state: any) => state.org);
  const [input, setInput] = useState({ _id: reduxOrg._id, wishlist: '' });
  
  const [submitting, setSubmitting] = useState(false);

  const dispatch = useDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInput((input) => ({
      ...input,
      [name]: value
    }));
  };
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setSubmitting(true);
    input.wishlist = formatLink(input.wishlist);
    const response: any = await API.saveWishlist(input).catch((err: any) =>
      console.log(err)
    );
    console.log(response);

    if (response?.status == 200) {
      const newReduxOrg = { ...reduxOrg };
      newReduxOrg.actionItems.wishlist = input.wishlist;
      console.log(newReduxOrg);
      dispatch(saveOrg(newReduxOrg));
      setSubmitting(false);
      handleSetWishList()
    }
  };

  const shorten = (str: string) => {
    if (str.length <= 40) {
      return str;
    } else {
      return `${str.slice(0, 40)}...`;
    }
  };

  const handleSetWishList = () => {
    if (setWishList) {
      setWishList(!wishList);
    }
  };

  return (

    <Modal centered show={wishList} onHide={handleSetWishList} className='manage-wish-popups'>
      <div className='manage-wish-wrap'>
        <span className='cancle-popup' onClick={handleSetWishList}>x</span>
        <h1 className='manage-marketplace-h2'>Your Wishlist <img src={three} style={{width:'22px' , marginTop : '-10px'}} alt="" /></h1>
        {reduxOrg.actionItems.wishlist ? (
            shorten(reduxOrg.actionItems.wishlist)
          ) : (
            <p>You currently don't have a wishlist.</p>
          )}

     
          <form className='manage-marketplace-addlist-form'>
            <label htmlFor="">Wishlist Link</label>
            <div className='manage-marketplace-addlist-input-row'>
              <input
                type='text'
                name='wishlist'
                placeholder='Paste your wishlist link here'
                onChange={handleChange}
                required
                value={input.wishlist || ''}
              />
            </div>

            <NeddieButton
              type='button'
              value={
                input.wishlist ? 'Update' : submitting ? 'Updating...' : 'Submit'
              }
              onClick={(e: any) => handleSubmit(e)}
            ></NeddieButton>
          </form>
      </div>
    </Modal>


  );
};

export default ManageWishlistPopup;
