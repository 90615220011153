import React from 'react';
// import './manageMarketPlacePopup.css';
import { Modal } from 'react-bootstrap';

interface ISharingOption {
    goToDaskboardPopup?: boolean; //to make the share symbol into a Neddie Button
    setGoToDaskboardPopup?: (value: boolean) => void; //to make the share button light
  }

const GoToDaskboardPopup: React.FC<ISharingOption> = ({goToDaskboardPopup , setGoToDaskboardPopup}) => {

  const handleSetWishList = () => {
    if (setGoToDaskboardPopup) {
        setGoToDaskboardPopup(!goToDaskboardPopup);
    }
  };

  return (
    <Modal centered show={goToDaskboardPopup} onHide={handleSetWishList} className='manage-wish-popups'>
      <div className='manage-wish-wrap'>
        <span className='cancle-popup' onClick={handleSetWishList}>x</span>
      {/* <h1 className='manage-marketplace-h2'>Manage Marketplace <img src={four} style={{width:'26px' , marginTop : '-5px'}} alt="" /></h1> */}

          <p className='go-to-desktop'>To see this feature, visit the dashboard on your desktop</p>

    </div>
    </Modal>
  );
};

export default GoToDaskboardPopup;
