import React from 'react';
// import Button from '../../../components/Button';
import './index.css';

interface IMobileInstall {
  close: () => void;
}

const MobileInstallPopup: React.FC<IMobileInstall> = ({ close }) => {
  return (
    <></>
    // <div className='desktop-install-popup-container'>
    //   <div className='desktop-install-popup-text'>
    //     Click the + in your address bar to install NEDDIE!
    //   </div>
    //   <div className='desktop-install-popup-close-button'>
    //     <Button onClickFunction={close} text='Close'></Button>
    //   </div>
    // </div>
  );
};

export default MobileInstallPopup;
