import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, Image } from 'react-bootstrap';
import './donorSetting.css';
import VerifiedCheck from '../../../images/Icon/verifiedCheck.png';
import { saveUser } from '../../../redux/actions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import API from '../../../utils/API';
import validations from '../../Validator/validationRules';
import {
    IValidationInputs,
    ValidateOverallForm,
    ValidationText
} from './donorSettingValidation';
import useDebounce from '../../../utils/debounce';

interface ISharingOption {
    setMobileSettingTab?: (value: number) => void; //to make the share button light
}

const DonorGeneral: React.FC<ISharingOption> = ({ setMobileSettingTab }) => {
    const [validationInputs, setValidationInputs] = useState<IValidationInputs>({
        overall_ValidationSuccess: undefined,
        Validation: {
            Password: {
                MinCharacterLength: undefined,
                MaxCharacterLength: undefined,
                NotEmpty: undefined,
                NumberIncluded: undefined
            },
            Email: {
                Format: undefined,
                NotEmpty: undefined
            },
            PhoneNumber: {
                MinCharacterLength: undefined,
                MaxCharacterLength: undefined,
                isNumber: undefined,
                NotEmpty: undefined
            }
        }
    });
    const [updatedSuccess, setUpdatedSuccess] = useState<boolean>(false);
    const [confirmPasswordSuccess, setConfirmPasswordSuccess] =
        useState<boolean>(false);
    const dispatch = useDispatch();
    const reduxUser = useSelector((state: any) => state.signin);
    const [formState, setFormState] = useState({
        password: '',
        confirmPassword: '',
        email: reduxUser.email,
        telephone: '3602303',
        _id: reduxUser._id
    });

    let debouncedSearchTerm: any;
    debouncedSearchTerm = useDebounce(formState.confirmPassword, 300);

    useEffect(() => {
        populatingDonorForm()

        return (() => {
            resetValidationsOnExit()
        })
    }, []);

    useEffect(() => {
        if (debouncedSearchTerm.length !== 0) {
            //make API call to confirm password
            console.log('confirming PASSWORD !!!!!!');
            API.checkPassword(reduxUser._id, debouncedSearchTerm)
                .then((response: any) => {
                    console.log(response.data.passwordMatch);
                    setConfirmPasswordSuccess(response.data.passwordMatch);
                })
                .catch((error) => {
                    console.log(error, confirmPasswordSuccess);
                });
        } else {
        }
    }, [debouncedSearchTerm]);

    const populatingDonorForm = async () => {
        let newFormState = {
            password: '',
            confirmPassword: '',
            email: reduxUser.email,
            telephone: 'unable to get your phone number',
            _id: reduxUser._id
        };
        //fetch User telephone
        await API.getUserPhoneNumber(reduxUser._id)
            .then((user: any) => {
                // console.log("the user from api",user)
                newFormState = { ...newFormState, telephone: user.data.telephone };
            })
            .catch((error: any) => {
                console.log(error.message);
                console.log('error');
            });

        setFormState(newFormState);
    };
    const resetValidationsOnExit = () => {
        //reset validations
        setValidationInputs({
            overall_ValidationSuccess: undefined,
            Validation: {
                Password: {
                    MinCharacterLength: undefined,
                    MaxCharacterLength: undefined,
                    NotEmpty: undefined,
                    NumberIncluded: undefined
                },
                Email: {
                    Format: undefined,
                    NotEmpty: undefined
                },
                PhoneNumber: {
                    MinCharacterLength: undefined,
                    MaxCharacterLength: undefined,
                    isNumber: undefined,
                    NotEmpty: undefined
                }
            }
        });
    };

    const validatingDonorSettings = (inputName: string, inputValue: string) => {
        let newValidationState = validationInputs;
        newValidationState.overall_ValidationSuccess = undefined;
        if (inputName === 'email') {
            newValidationState.Validation.Email.Format =
                validations.isEmail(inputValue);
            newValidationState.Validation.Email.NotEmpty =
                validations.NotEmpty(inputValue);
        } else if (inputName === 'password') {
            newValidationState.Validation.Password.MaxCharacterLength =
                validations.maxStringLength(inputValue, 40);
            newValidationState.Validation.Password.MinCharacterLength =
                validations.minStringLength(inputValue, 8);
            newValidationState.Validation.Password.NumberIncluded =
                validations.hasNumber(inputValue);
            newValidationState.Validation.Password.NotEmpty =
                validations.NotEmpty(inputValue);
        } else if (inputName === 'telephone') {
            newValidationState.Validation.PhoneNumber.MaxCharacterLength =
                validations.maxStringLength(inputValue, 13);
            newValidationState.Validation.PhoneNumber.MinCharacterLength =
                validations.minStringLength(inputValue, 10);
            newValidationState.Validation.PhoneNumber.isNumber =
                validations.isNumber(inputValue);
            newValidationState.Validation.PhoneNumber.NotEmpty =
                validations.NotEmpty(inputValue);
        }
        setValidationInputs({ ...newValidationState });
    };

    const handleChange = (e: any) => {
        //change updateSuccess to false if the user is changing the inputs in the form
        updatedSuccess === true && setUpdatedSuccess(false);

        setFormState({
            ...formState,
            [e.target.name]: e.target.value
        });

        //validating input settings
        e.target.name !== 'confirmPassword' &&
            validatingDonorSettings(e.target.name, e.target.value);
    };



    const SaveUserSetting = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        console.log(formState);

        //validate the overall form then proceed if successfull
        if (ValidateOverallForm(validationInputs) === false) {
            setValidationInputs({
                ...validationInputs,
                overall_ValidationSuccess: false
            });
            return;
        }

        //Update User
        const res: any = await API.updateUser(formState).catch((err: any) =>
            console.log(err)
        );

        if (res.status === 200) {
            setUpdatedSuccess(true);
            dispatch(saveUser({ ...reduxUser, email: formState.email }));
        }
    };

    // const deleteUser = () => { };

    const handleMobileSetting = (index: number) => {
        if (setMobileSettingTab) {
            setMobileSettingTab(index);
        }
    };

    return (
        <div className="pass-reset-deshboard">
            <div className="title-cross" style={{ marginBottom: '40px' }}>
                <h1>General Info</h1>
                <span onClick={() => { handleMobileSetting(0) }}>x</span>
            </div>
            <div className="contianer">
                <div className="row" style={{ justifyContent: 'center' }}>
                    <div className="col-12">
                        <div className='navbar-UserSetting-Modal' style={{position:'relative'}}>
                            {updatedSuccess === true && (
                                <div className='donorSetting-updatedGreenCheck' 
                                style={{position:'absolute' , zIndex : 5, top:'40%' , left : '50%', transform :'translate(-50%, -50%)'}}>
                                    <Image src={VerifiedCheck} style={{width : '30%'}}/>
                                </div>
                            )}

                            <div className='donorSetting-modal'>
                                <Form className='donorSetting-modal-form' >
                                    {/* <Form.Group>
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control
                                            value='*********'
                                            name='password'
                                            type='password'
                                            placeholder='Password'
                                            disabled
                                        />
                                    </Form.Group> */}
                                    <Form.Group>
                                        <Form.Label>EMAIL</Form.Label>
                                        <Form.Control
                                            value={formState.email}
                                            name='email'
                                            type='text'
                                            placeholder='Email'
                                            className='donorSetting-modal-input'
                                            onChange={(e: any) => handleChange(e)}
                                        />
                                        <div className='donorSettings-Validations'>
                                            <ValidationText
                                                validationState={validationInputs?.Validation.Email.Format}
                                                text='Invalid Format'
                                            />
                                            <ValidationText
                                                validationState={validationInputs?.Validation.Email.NotEmpty}
                                                text='Email cannot be empty'
                                            />
                                        </div>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>PHONE NUMBER</Form.Label>
                                        <Form.Control
                                            value={formState.telephone}
                                            name='telephone'
                                            type='text'
                                            placeholder='Phone Number'
                                            className='donorSetting-modal-input'
                                            onChange={(e: any) => handleChange(e)}
                                        />
                                        <div className='donorSettings-Validations'>
                                            <ValidationText
                                                validationState={
                                                    validationInputs?.Validation.PhoneNumber.MaxCharacterLength
                                                }
                                                text='Phone number is invalid'
                                            />
                                            <ValidationText
                                                validationState={
                                                    validationInputs?.Validation.PhoneNumber.MinCharacterLength
                                                }
                                                text='Phone number too short'
                                            />
                                            <ValidationText
                                                validationState={
                                                    validationInputs?.Validation.PhoneNumber.isNumber
                                                }
                                                text='Phone number should not contains letters'
                                            />
                                            <ValidationText
                                                validationState={
                                                    validationInputs?.Validation.PhoneNumber.NotEmpty
                                                }
                                                text='Phone number cannot be empty'
                                            />
                                        </div>
                                    </Form.Group>
                                    <Row className='Donor-setting-action-Buttons'>
                                        <Col>
                                            <div style={{ textAlign: 'center' }}>

                                                <Button
                                                    variant='primary'
                                                    type='submit'
                                                    className='donorSetting-modal-save backgroundColor-ocean '
                                                    onClick={(e: any) => SaveUserSetting(e)}
                                                >
                                                    Save
                                                </Button>
                                            </div>
                                        </Col>

                                        {/* <Col className='text-right'>
                            <small onClick={deleteUser} className='text-secondary'>
                                <u>Delete Account</u>
                            </small>
                        </Col> */}
                                    </Row>
                                </Form>
                            </div>
                            <div className='donorSetting-overall-validation-message modal-footer border-0'>
                                <ValidationText
                                    validationState={validationInputs?.overall_ValidationSuccess}
                                    text='Invalid Entry'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default DonorGeneral;


