import React, { useState, useEffect } from 'react';
import '@stripe/stripe-js';
import { useSelector } from 'react-redux';
import API from '../../../utils/API';
import TransactionsNew from '../managePay/TransactionsNew';
import './dashboardSetting.css'

const ManagePay: React.FC = (props: any) => {
  const reduxOrg = useSelector((state: any) => state.org);
  const [onboardComplete, setOnboardComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [balance, setBalance] = useState<any>();
  const [transactionList, setTransactionList] = useState([]);

  useEffect(() => {
    if (reduxOrg._id) {
      setLoading(true);
      console.log(reduxOrg._id, 'reduxOrg._id');
      console.log(reduxOrg.actionItems?.stripeID, 'reduxOrg._id');

      API.getStripeAcct(reduxOrg.actionItems?.stripeID)
        .then((res: any) => {
          console.log(res, 'ddddddddd');
          if (res.data?.details_submitted) {
            setOnboardComplete(true);

            API.findNP(reduxOrg._id).then((nonprofit: any) => {
              if (!nonprofit.stripeComplete) {
                API.updateNP({
                  ...nonprofit.data,
                  stripeComplete: true
                }).catch((err: any) => console.log(err));
              }
            });
          }
          setLoading(false);
        })
        .catch((err: any) => {
          console.log(err, 'ddddddddd')

        });
    }
  }, []);

  useEffect(() => {
    API.getStripeBalance(reduxOrg.actionItems?.stripeID)
      .then((res: any) => {
        console.log(balance);
        setBalance(res.data);
      })
      .catch((err: any) => console.log(err));

    API.getTransactions(reduxOrg.actionItems?.stripeID)
      .then((res: any) => {
        console.log(res, 'transaction results');
        console.log(res);
        setTransactionList(res.data.data);
      })
      .catch((err: any) => console.log(err, 'transaction results'));
  }, [onboardComplete]);

  const renderOnboardingComplete = () => {
    return (
      <>
        <TransactionsNew
          list={transactionList.filter(
            (transaction: any) => transaction.type === 'charge'
          )}
        />
      </>
    );
  };

  const renderManagePay = () => {
    if (loading) {
      return <h3>LOADING</h3>;
    } else if (onboardComplete) {
      return renderOnboardingComplete();
    }
  };

  return (
    <div className='manage-pay-wrapper'>
      <h1 className='dashboard-manage-pay-h2' style={{ fontWeight: 700, fontSize: '24px', color: '#406dc9' }}>Donations</h1>
      {loading ? null :
        <>
          {!onboardComplete && <div style={{height:'300px'}} className='no-data-to-show'>Connect to Stripe on Manage Pay to start receiving donations</div>}
        </>
      }
      {renderManagePay()}
    </div>
  );
};

export default ManagePay;