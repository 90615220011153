const signinOrgReducer = (state = {}, action: any) => {
  switch (action.type) {
    case 'signin/saveOrg':
      return (state = action.payload);
    case 'signin/deleteOrg':
      return (state = {});
    default:
      return state;
  }
};

export default signinOrgReducer;
