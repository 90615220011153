import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
// import ReactTooltip from 'react-tooltip';
import API from '../../../utils/API';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { ProgressBar } from 'react-bootstrap';
import './avtiveFundraisersprogress.css';
import { useHistory } from 'react-router-dom';

const AvtiveFundraisersprogress: React.FC = () => {
  const reduxOrg = useSelector((state: any) => state.org);
  const [campaigns, setCampaigns] = useState<any>([]);
  const history = useHistory();

  useEffect(() => {
    API.getUpdate().then((result) => {
    });

    console.log(campaigns, 'campaigns');
    
  }, []);

  useEffect(() => {
    API.getNpCampaigns(reduxOrg._id).then((result) => {
      console.log(result.data, 'campaigns');
      setCampaigns(
        result.data.sort((a: any, b: any) => b.createdAt - a.createdAt)
      );
    });
    // console.log(campaigns, 'campaigns');
  }, []);

  const goToProfile = () => {
    history.push(`/nonprofit/${reduxOrg._id}?sec=fundraser`);
  };


  return (



    <div className='dashboard-info-social-media-card'>
      <div className='dashBoard-infocard-top-row'>
        <h2 className='dashboard-info-h2'>Active Fundraisers Progress</h2>
      </div>

      {campaigns.length > 0 ?
        <>
        <div className="campaings-parent">
          <div className="no-campaings">
            {campaigns.map((camp: any, i: number) => {
              console.log(camp , 'campaigns');
              
              let progressPercent = Math.round(
                (parseInt(camp.progress) / parseInt(camp.goal)) * 100
              );
              return (
                <div className='active-fund-raser'>
                  <div className='dashboard-active-fundaraiser-body'>
                    <div>
                      <span className='title'>
                        {camp.name.length <= 25
                          ? camp.name
                          : camp.name.substring(0, 24) + '...'}
                      </span>
                      <span className='dashboard-goal-text'>
                        ${camp.progress} of ${camp.goal} raised
                      </span>
                      <div className='dashboard-progress-bar'>
                        <ProgressBar now={progressPercent} max={100} min={0} />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        </>
        :
        <div >
          <p>Currently you do not have any campaigns running.</p>
          <div className="buttomm">
            <button onClick={goToProfile}>Create One</button>
          </div>
        </div>
      }
    </div>
  );
};

export default AvtiveFundraisersprogress;
